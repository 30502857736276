import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { minSpacing, smallSpacing } from '../enums/Spacings'
import Images from '../libs/Images'
import { Texts } from '../libs/Texts'
import { useSourceFile } from '../libs/VitecNextProductSourceFileInfoHelper'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'

export function VitecNextProductSourceFileInfoDeprecated({ leopardOrder }: { leopardOrder?: LeopardOrder }) {
  const sourceFile = useSourceFile(leopardOrder)

  const [fileName] = sourceFile || []

  return (
    <Box direction="row" fullWidth link>
      <Box>
        <FigmaImageContainer imageKey={Images.pngDocIcon} />
      </Box>
      <Box left spacing={smallSpacing}>
        <FigmaText textKey={Texts.standardDocumentSourceLabel} />
      </Box>
      <Box left spacing={minSpacing}>
        <FigmaText textKey={Texts.standardDocumentSourceFileName} text={fileName} />
      </Box>
    </Box>
  )
}
