export enum Product {
  IMAGE_CAPTIONS = 'IMAGE_CAPTIONS',
  CONSTRUCTION_INFO = 'CONSTRUCTION_INFO',
  CONSTRUCTION_INFO_EXPERIMENTAL = 'CONSTRUCTION_INFO_EXPERIMENTAL',
  BASIC_ASSOCIATION = 'BASIC_ASSOCIATION',
  SELF_DECLERATION = 'SELF_DECLERATION',
  DESCRIPTIONS = 'DESCRIPTIONS',
  AREA_DESCRIPTION = 'AREA_DESCRIPTION'
}

export const LiveProducts: { [key in Product]: boolean } = {
  IMAGE_CAPTIONS: true,
  CONSTRUCTION_INFO: true,
  CONSTRUCTION_INFO_EXPERIMENTAL: false,
  DESCRIPTIONS: true,
  BASIC_ASSOCIATION: true,
  AREA_DESCRIPTION: true,
  SELF_DECLERATION: true
}

export const LiveProductNames = Object.entries(LiveProducts)
  .filter(([_, value]) => value)
  .map(([key]) => key as Product)
