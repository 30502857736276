import { Product } from './Product.js'

export type IsVitecNextProductEnabledResponse = {
  vitecNextProduct: Product
  isEnabled: boolean
  noOfDocuments: number
  code?: IsVitecNextProductEnabledResponseCode
  extra?: {
    bytes?: number
    url?: string
  }
}

export enum IsVitecNextProductEnabledResponseCode {
  DOCUMENT_NOT_FOUND = 'DOCUMENT_NOT_FOUND',
  DOCUMENT_TOO_BIG = 'DOCUMENT_TOO_BIG',
  ADDRESS_NOT_FOUND = 'ADDRESS_NOT_FOUND'
}
