import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { SuccessColor, Yellow25, Yellow50 } from '../../../../pure/libs/Colors'
import { YYYYMMDD_HHmm } from '../../../../pure/libs/Consts'
import {
  BasicAssociationFields,
  BasicAssociationResponseItemsKey
} from '../../../../pure/libs/VitecNextOrderProductBasicAssociationItemsPure'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { minSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { fulfillLeopardOrder } from '../libs/CloudFunctionsApiHandler'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import Images from '../libs/Images'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { HardCodedTexts, Texts } from '../libs/Texts'
import { VitecNextOrderProductBasicAssociationItems } from '../libs/VitecNextOrderProductBasicAssociationItems'
import { onCopyText } from '../libs/onClickCopy'
import Box from './Box'
import { Checkbox } from './Checkbox'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import Loader from './Loader'
import Tooltip from './Tooltip'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { VitecNextOrderProductBasicAssociationItemEmptyState } from './VitecNextOrderProductBasicAssociationItemEmptyState'
import { BorderRadixes } from '../enums/BorderRadixes'
import styled from '@emotion/styled'
import { HIGHLIGHTED_TOP_ABSOLUTE_POSITION } from '../libs/HardCodedSizes'
import { Product } from '../../../../pure/leopard/Product'

export function VitecNextOrderProductBasicAssociationItem({
  itemKey,
  basicAssociationFields,
  setBasicAssociationFields,
  vitecNextOrder,
  enableUpload = true,
  leopardOrder,
  onClickHighlight,
  isSelected
}: {
  itemKey: BasicAssociationResponseItemsKey
  basicAssociationFields: BasicAssociationFields
  setBasicAssociationFields: (fields: BasicAssociationFields) => void
  enableUpload?: boolean
  vitecNextOrder: VitecNextOrder
  leopardOrder: LeopardOrder
  onClickHighlight?: (itemKey: BasicAssociationResponseItemsKey) => unknown
  isSelected?: boolean
}) {
  const ref = useRef<HTMLDivElement>(null)
  const { state } = useAppState()
  const { orderId: vitecNextOrderId } = vitecNextOrder
  const vitecNextProduct = Product.BASIC_ASSOCIATION
  const [loading, setLoading] = useState(false)

  const item = VitecNextOrderProductBasicAssociationItems[itemKey]

  const { checked = true, fulfilledAt, isFulfilling, value } = basicAssociationFields[itemKey]

  useEffect(() => {
    if (isSelected) ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [isSelected])

  if (!value) return <VitecNextOrderProductBasicAssociationItemEmptyState itemKey={itemKey} />

  const isLoading = loading || isFulfilling

  const onClickChecked = (checked: boolean, key: BasicAssociationResponseItemsKey, fields: BasicAssociationFields) =>
    setBasicAssociationFields({ ...fields, [key]: { ...fields[key], checked, isFulfilling: false } })

  const onClickUpload = () =>
    Promise.resolve(setLoading(true))
      .then(() =>
        fulfillLeopardOrder(
          {
            legacyVitecNextOrderId: vitecNextOrderId,
            externalEstateId: leopardOrder.externalEstateId,
            vitecNextProduct,
            basicAssociationFulfillRequest: {
              key: itemKey
            }
          },
          state
        )
      )
      .catch(captureAndNotifyError)
      .finally(() => setLoading(false))

  return (
    <Container direction="row" fullWidth justify="space-between" isSelected={isSelected} position="relative">
      <Box ref={ref} style={{ position: 'absolute', top: -HIGHLIGHTED_TOP_ABSOLUTE_POSITION }} />
      <Box fullWidth direction="row">
        {enableUpload && (
          <Box
            onClick={() =>
              onClickChecked(!checked, itemKey as BasicAssociationResponseItemsKey, basicAssociationFields)
            }
            pointer
            top
            spacing={minSpacing}
          >
            <Checkbox value={checked} />
          </Box>
        )}
        <Box left spacing={smallSpacing} fullWidth>
          <FigmaText styleTextKey={HardCodedTexts.cssBaItemHeader} textKey={item.textKey} />
          <BackgroundTextContainer>
            <TextContainer isSelected={isSelected}>
              <FigmaTextWithLinebreaks textKey={Texts.BasicAssociationBaAnimal} text={value} />
            </TextContainer>
          </BackgroundTextContainer>
        </Box>
      </Box>
      <Box direction="row">
        {enableUpload && (
          <Box left spacing={smallSpacing} pointer>
            <Tooltip
              title={
                fulfilledAt
                  ? formatText(getFigmaText(Texts.standardCtaUploadedToNextAt), [
                      dayjs(fulfilledAt).format(YYYYMMDD_HHmm)
                    ])
                  : getFigmaText(Texts.standardCtaUploadToNext)
              }
              placement="top"
              backgroundColor={fulfilledAt ? SuccessColor : undefined}
              onClick={() => onClickUpload()}
            >
              <Box>
                {isLoading && <Loader size={Images.uploadArrow.width} />}
                {!isLoading && (
                  <FigmaImageContainer imageKey={fulfilledAt ? Images.uploadedArrow : Images.uploadArrow} />
                )}
              </Box>
            </Tooltip>
          </Box>
        )}
        <Box left spacing={smallSpacing} pointer onClick={() => onCopyText(value)}>
          <Tooltip title={getFigmaText(Texts.standardCtaCopyText)} placement="top">
            <FigmaImageContainer imageKey={Images.copyBlack} />
          </Tooltip>
        </Box>
        <Box
          left
          spacing={smallSpacing}
          pointer
          onClick={() => {
            onClickHighlight?.(itemKey)
          }}
        >
          <Tooltip title={getFigmaText(Texts.BasicAssociationBaHighlightText)} placement="top">
            <FigmaImageContainer imageKey={Images.highlight} />
          </Tooltip>
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled(Box)<{ isSelected?: boolean }>`
  background-color: ${({ isSelected }) => (isSelected ? Yellow50 : 'transparent')};
  border-radius: ${BorderRadixes.regular};
`

const BackgroundTextContainer = styled(Box)`
  background-color: white;
  border-radius: ${BorderRadixes.regular};
`

const TextContainer = styled(Box)<{ isSelected?: boolean }>`
  background-color: ${({ isSelected }) => (isSelected ? Yellow25 : 'transparent')};
  border-radius: ${BorderRadixes.regular};
`
