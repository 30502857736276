import { getDownloadURL, ref } from 'firebase/storage'
import { useEffect, useState } from 'react'
import {
  BasicAssociationItems,
  JaguarBoundingBox as BoundingBox
} from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { removeDuplicateStrings } from '../../../../pure/libs/Common'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { PdfViewerProps } from '../components/PdfViewer'
import { storage } from '../libs/Firebase'

export function useDefaultPdfViewerPropSetsForBasicAssociationResults({
  leopardOrder,
  basicAssociationItems
}: {
  leopardOrder?: LeopardOrder
  basicAssociationItems?: BasicAssociationItems | null
}): {
  data?: Partial<PdfViewerProps>[]
  loading: boolean
} {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Partial<PdfViewerProps>[]>([])

  useEffect(() => {
    if (!leopardOrder || !basicAssociationItems) return

    const fileNames = removeDuplicateStrings(
      Object.values(basicAssociationItems)
        .flatMap((v) => v?.bounding_boxes)
        .map((v) => v?.filename)
        .filter(Boolean) as string[]
    )

    setLoading(true)
    Promise.all(
      fileNames.map((fileName) =>
        getDefaultPdfViewerPropsForBasicAssociationResults({ leopardOrder, basicAssociationItems, fileName })
      )
    )
      .then((propSets) => setData(propSets.filter(Boolean) as Partial<PdfViewerProps>[]))
      .finally(() => setLoading(false))
  }, [basicAssociationItems, !!leopardOrder])

  return { data, loading }
}

export async function getDefaultPdfViewerPropsForBasicAssociationResults({
  leopardOrder,
  basicAssociationItems,
  fileName
}: {
  leopardOrder?: LeopardOrder
  basicAssociationItems?: BasicAssociationItems
  fileName: string
}): Promise<Partial<PdfViewerProps> | undefined> {
  const gsUrl = Object.values(leopardOrder?.documentUrls || {}).find((gsUrl) => !!fileName && gsUrl.includes(fileName))

  if (!gsUrl) return

  return {
    gsUrl,
    boundingBoxes: Object.values(basicAssociationItems || {})
      .flatMap((v) => v?.bounding_boxes)
      .filter((v) => !!v && v.filename === fileName) as BoundingBox[]
  }
}
