import { Product } from '../../../../pure/leopard/Product'
import { useNow } from '../hooks/useNow'
import Box from './Box'
import { VitecNextOrderItem } from './VitecNextOrderItem'

export function DashboardGrid({ vitecNextOrder }: { vitecNextOrder; now: string }) {
  const now = useNow()

  return (
    <Box fullWidth>
      <Box direction="row" fullHeight fullWidth justify="space-between">
        {[Product.IMAGE_CAPTIONS, Product.CONSTRUCTION_INFO, Product.DESCRIPTIONS]
          .filter((v) => !!v)
          .map((vitecNextProduct, i) => (
            <VitecNextOrderItem
              key={i}
              vitecNextOrder={vitecNextOrder}
              vitecNextProduct={vitecNextProduct as Product}
              now={now}
            />
          ))}
      </Box>
      <Box direction="row" fullHeight top fullWidth justify="space-between">
        {[Product.BASIC_ASSOCIATION, Product.SELF_DECLERATION, Product.AREA_DESCRIPTION]
          .filter((v) => !!v)
          .map((vitecNextProduct, i) => (
            <VitecNextOrderItem
              key={i}
              vitecNextOrder={vitecNextOrder}
              vitecNextProduct={vitecNextProduct as Product}
              now={now}
            />
          ))}
      </Box>
    </Box>
  )
}
