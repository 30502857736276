import { IsVitecNextProductEnabledResponse } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import { useJaguarResultExperimental } from '../hooks/useJaguarResults'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { IsVitecNextProductEnabledInfo } from '../libs/IsVitecNextProductEnabledInfo'
import { Texts } from '../libs/Texts'
import Box from './Box'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'

type Props = VitecNextOrderProductProps & { isVitecNextProductEnabled: IsVitecNextProductEnabledResponse }

export function DashboardListItemDetailsProductNotEnabled(props: Props) {
  const { vitecNextProduct, isVitecNextProductEnabled } = props
  const { data: vitecNextOrder } = useVitecNOOrder(props.vitecNextOrderId)
  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderId({ externalEstateId: vitecNextOrder?.estateId, product: props.vitecNextProduct })
  )

  const { data: results } = useJaguarResultExperimental(leopardOrder)

  if (!isVitecNextProductEnabled?.code) return null

  const text = IsVitecNextProductEnabledInfo[isVitecNextProductEnabled.code].getText({
    vitecNextOrder,
    isVitecNextProductEnabled,
    vitecNextProduct
  })

  if (results?.status === 'error')
    return (
      <Box fullWidth align="center">
        <Box>
          <VitecNextOrderItemStatusView textKey={Texts.standardNotificationCiError} type="error" />
        </Box>
      </Box>
    )

  return (
    <Box fullWidth align="center">
      <Box>
        <VitecNextOrderItemStatusView type="warning" text={text} />
      </Box>
    </Box>
  )
}
