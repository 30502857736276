import { useParams } from 'react-router-dom'
import { Dashboard } from '../components/Dashboard'
import useQueryParams from '../libs/useQueryParams'
import { VismaLandingQueryParams } from '../../../../pure/types/QueryParamTypes'

const defaultVitecNextOrderId = '1FFDE932-EAD6-4C4D-AB90-F30C5B1F8D72' // Eksempel på en default verdi
export const VismaCoreLandingPage = () => {
  const { CaseId = 'default' } = useQueryParams<VismaLandingQueryParams>()

  const vitecNextOrderId =
    {
      '67188': 'A44C2C11-5CE7-45E8-AA01-C191429089ED',
      default: defaultVitecNextOrderId
    }[CaseId] || defaultVitecNextOrderId

  return <Dashboard vitecNextOrderId={vitecNextOrderId} />
}
