import styled from '@emotion/styled'
import { ImageCaptionsResultsResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import Colors from '../../../../pure/libs/Colors'
import { getLeopardOrderIdForOrder } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { minSpacing } from '../enums/Spacings'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import Box from './Box'
import { LoadingPage } from './LoadingPage'
import { VitecNextOrderProductImageCaptionsResultExperiemental } from './VitecNextOrderProductImageCaptionsResultExperiemental'
import { useJaguarResultExperimental } from '../hooks/useJaguarResults'
import { useLeopardOrder } from '../hooks/useLeopardOrder'

export const DashboardListItemDetailsImageCaptionsComponent = (props: VitecNextOrderProductProps) => {
  const { vitecNextOrderId, vitecNextProduct } = props
  const { data: vitecNextOrder, isLoading } = useVitecNOOrder(vitecNextOrderId)
  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderIdForOrder({ vitecNextProduct, vitecNextOrder }))

  const { data: jaguarImageCaptions } = useJaguarResultExperimental<ImageCaptionsResultsResponse>(leopardOrder)

  const captions = jaguarImageCaptions?.structured_outputs?.captions || []

  if (isLoading) return <LoadingPage />
  if (!vitecNextOrder) return <Box />

  return (
    <Container align="center" fullWidth>
      <Box direction="row" style={{ flexWrap: 'wrap' }} fullPadding fullWidth justify="center">
        {captions?.map((caption, key) => {
          return (
            <Box key={key} width="33.33%" fullPadding spacing={minSpacing}>
              <VitecNextOrderProductImageCaptionsResultExperiemental
                caption={caption}
                index={key}
                leopardOrder={leopardOrder}
                enableCheckbox={false}
              />
            </Box>
          )
        })}
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`
