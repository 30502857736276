import {
  SelfDeclarationItem,
  SelfDeclarationResultsResponse
} from '../../../../pure/leopard/JaguarExperimentalTypesDerived'

export function formatSelfDeclarationItem(r: SelfDeclarationItem): { question: any; answer: any } {
  let question = cleanText(r.question)
  let answer = cleanText(r.answer)

  if (question.includes('- Ja')) {
    question = question.replace('- Ja', '')
    answer = `- Ja, ${answer}`
  }

  return { question, answer }
}

function cleanText(text: string): string {
  return text.replaceAll('◆', '-').replace(/\d+\./g, '').trim()
}

export function getSelfDeclarationItems(jaguarResults: SelfDeclarationResultsResponse | undefined) {
  return (
    (jaguarResults?.structured_outputs?.items
      ?.filter((r) => r.answer)
      .sort(
        (a, b) => parseInt(a.question_number as string) - parseInt(b.question_number as string)
      ) as SelfDeclarationItem[]) || []
  )
}
