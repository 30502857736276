import styled from '@emotion/styled'
import { useEffect } from 'react'
import { FeatureFlag } from '../../../../pure/enums/FeatureFlags'
import {
  BasicAssociationItem,
  BasicAssociationItems,
  BasicAssociationResultsResponse
} from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import Colors from '../../../../pure/libs/Colors'
import {
  BasicAssociationField,
  BasicAssociationFields,
  BasicAssociationResponseItemsKey,
  HighlightedField,
  VitecNextOrderProductBasicAssociationItemsPure
} from '../../../../pure/libs/VitecNextOrderProductBasicAssociationItemsPure'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductBasicAssociationQueryParams } from '../../../../pure/types/QueryParamTypes'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import { useBasicAssociaionFieldsState } from '../hooks/useBasicAssociaionFieldsState'
import { useDefaultPdfViewerPropSetsForBasicAssociationResults } from '../hooks/useDefaultPdfViewerPropsForBasicAssociationResults'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import { useState } from '../hooks/useState'
import { VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { success } from '../libs/ToastHelper'
import { shouldShowBasicAssociationField } from '../libs/VitecNextOrderProductBasicAssociationHelper'
import {
  BasicAssociationKeysAll,
  BasicAssociationKeysThatCanBeCantBeUploaded,
  BasicAssociationKeysThatCanBeUploaded
} from '../libs/VitecNextOrderProductBasicAssociationItems'
import useQueryParams from '../libs/useQueryParams'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { JsonView } from './JsonView'
import Loader from './Loader'
import { LoadingPage } from './LoadingPage'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import { VitecNextOrderProductBasicAssociationItem } from './VitecNextOrderProductBasicAssociationItem'
import { VitecNextOrderProductButtonsDeprecated as VitecNextOrderProductButtons } from './VitecNextOrderProductButtonsDeprecated'
import { VitecNextOrderProductHeader } from './VitecNextOrderProductHeader'
import { WithPdfDrawer } from './WithPdfDrawer'
import { useDrawerContext } from './useDrawerContext'
import { useJaguarResultExperimental } from '../hooks/useJaguarResults'
import { useLeopardOrder } from '../hooks/useLeopardOrder'

// http://localhost:5176/vitec-next-order/D9482551-37A6-4120-B4D6-03336FB1DF93/BASIC_ASSOCIATION

export const VitecNextOrderProductBasicAssociationExperimental = (props: VitecNextOrderProductProps) => {
  const queryParams = useQueryParams<VitecNextOrderProductBasicAssociationQueryParams>()
  const enableDebug = useFeatureFlag(FeatureFlag.enableDebug)
  const [selectedItemKey, setSelectedItemKey] = useState<BasicAssociationResponseItemsKey>(undefined)
  const drawerContext = useDrawerContext('basic_association')
  const { vitecNextOrderId, vitecNextProduct } = props
  const { data: vitecNextOrder, isLoading: isLoadingVitecNextOrder } = useVitecNOOrder(vitecNextOrderId)

  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderId({ externalEstateId: vitecNextOrder?.estateId, product: vitecNextProduct })
  )

  const { data: jaguarResult, isLoading: isLoadingJaguarResults } =
    useJaguarResultExperimental<BasicAssociationResultsResponse>(leopardOrder)

  const { basicAssociationFields, updateBasicAssociationFields } = useBasicAssociaionFieldsState(vitecNextOrderId)

  const onClickHighlight = (itemKey: BasicAssociationResponseItemsKey) => {
    const item: BasicAssociationItem = jaguarResult?.structured_outputs?.basic_association_info?.[itemKey]
    setSelectedItemKey(itemKey)
    drawerContext.highlight(item as HighlightedField)
  }

  const basicAssociationItems = jaguarResult?.structured_outputs?.basic_association_info

  const { data: pdfViewerPropSets = [] } = useDefaultPdfViewerPropSetsForBasicAssociationResults({
    leopardOrder,
    basicAssociationItems: basicAssociationItems as BasicAssociationItems
  })

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!basicAssociationItems) return

      const itemKeys = [...BasicAssociationKeysThatCanBeUploaded, ...BasicAssociationKeysThatCanBeCantBeUploaded]
        .filter((key) => !!basicAssociationItems[key]?.value)
        .filter((key) => VitecNextOrderProductBasicAssociationItemsPure[key].enabled !== false)

      const currentIndex = selectedItemKey ? itemKeys.indexOf(selectedItemKey) : -1

      if (event.key === 'ArrowDown' && currentIndex < itemKeys.length - 1) {
        onClickHighlight(itemKeys[currentIndex + 1])
      } else if (event.key === 'ArrowUp' && currentIndex > 0) {
        onClickHighlight(itemKeys[currentIndex - 1])
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [basicAssociationItems, selectedItemKey])

  useEffect(() => {
    if (!jaguarResult) return
    if (queryParams.selectedItemKey && BasicAssociationKeysAll.includes(queryParams.selectedItemKey))
      onClickHighlight(queryParams.selectedItemKey as BasicAssociationResponseItemsKey)
  }, [queryParams.selectedItemKey, jaguarResult])

  const isLoading = (isLoadingVitecNextOrder || isLoadingJaguarResults) && !basicAssociationFields

  if (!vitecNextOrder) return <Box />
  if (!leopardOrder) return <Box />

  const successText = getFigmaText(Texts.standardNotificationUploadComplete)

  const isSelected = (itemKey: BasicAssociationResponseItemsKey) => selectedItemKey === itemKey

  return (
    <WithPdfDrawer pdfViewerPropSets={pdfViewerPropSets} drawerContext={drawerContext}>
      <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
        <Box>
          <VitecNextOrderProductBackButton vitecNextOrderId={vitecNextOrderId} />
        </Box>
        <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
          <VitecNextOrderProductHeader
            vitecNextOrder={vitecNextOrder}
            headerTextKey={Texts.BasicAssociationBaHeaderText}
            vitecNextProduct={vitecNextProduct}
          />
          <Box top>
            <FigmaImageContainer imageKey={Images.gazelleInCircle} />
          </Box>
          <Box fullWidth top spacing={smallSpacing}>
            <VitecNextOrderProductButtons
              vitecNextOrderId={vitecNextOrderId}
              vitecNextProduct={vitecNextProduct}
              fulfillMentSuccessText={successText}
              enableCopy={false}
              onPostFulfill={() => success(successText)}
              onPreFulfill={async () => {
                basicAssociationFields &&
                  (await updateBasicAssociationFields(
                    Object.entries(basicAssociationFields).reduce((a, [k, v]) => {
                      if (v.checked === false) return a

                      return {
                        ...a,
                        [k]: {
                          ...v,
                          fulfilledAt: null,
                          isFulfilling: true
                        } as BasicAssociationField
                      }
                    }, {} as BasicAssociationFields)
                  ))
              }}
            />
          </Box>
          <Box top />
          {jaguarResult?.status === 'processing' && <LoadingPage />}
          {jaguarResult?.status === 'success' && (
            <Box fullWidth>
              <Box fullWidth>
                <Container fullWidth fullPadding position="relative">
                  <Box direction="row" align="center">
                    <FigmaText textKey={Texts.ConstructionCiTextHeader} />
                  </Box>
                  {isLoading && (
                    <Box fullWidth align="center" fullPadding>
                      <Loader />
                    </Box>
                  )}
                  {basicAssociationFields &&
                    BasicAssociationKeysThatCanBeUploaded
                      //
                      .filter((key) => shouldShowBasicAssociationField({ itemKey: key, basicAssociationFields }))
                      .map((key, i) => {
                        return (
                          <Box top fullWidth key={i}>
                            <VitecNextOrderProductBasicAssociationItem
                              basicAssociationFields={basicAssociationFields}
                              setBasicAssociationFields={updateBasicAssociationFields}
                              itemKey={key as BasicAssociationResponseItemsKey}
                              leopardOrder={leopardOrder}
                              vitecNextOrder={vitecNextOrder}
                              onClickHighlight={onClickHighlight}
                              isSelected={isSelected(key)}
                            />
                          </Box>
                        )
                      })}
                </Container>
              </Box>
              <Box fullWidth top>
                <Container fullWidth fullPadding position="relative">
                  <Box direction="row" align="center">
                    <FigmaText textKey={Texts.BasicAssociationSuggestedTextHeaderNoUpload} />
                  </Box>
                  {basicAssociationFields &&
                    BasicAssociationKeysThatCanBeCantBeUploaded
                      //
                      .filter((key) => shouldShowBasicAssociationField({ itemKey: key, basicAssociationFields }))
                      .map((key, i) => {
                        return (
                          <Box top fullWidth key={i}>
                            <VitecNextOrderProductBasicAssociationItem
                              basicAssociationFields={basicAssociationFields}
                              setBasicAssociationFields={updateBasicAssociationFields}
                              itemKey={key as BasicAssociationResponseItemsKey}
                              enableUpload={false}
                              vitecNextOrder={vitecNextOrder}
                              leopardOrder={leopardOrder}
                              onClickHighlight={onClickHighlight}
                              isSelected={isSelected(key)}
                            />
                          </Box>
                        )
                      })}

                  {enableDebug && <JsonView data={jaguarResult || {}} />}
                </Container>
              </Box>
            </Box>
          )}
        </Box>
        <Box visibility="hidden">
          <VitecNextOrderProductBackButton vitecNextOrderId={vitecNextOrderId} />
        </Box>
      </Box>
    </WithPdfDrawer>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`
