import { Product } from '../leopard/Product.js'
import { VitecNextOrder } from '../types/VitecNODerivedTypes.js'

export type GetLeopardOrderIdProps = {
  externalEstateId?: string
  product: Product
}

export const getLeopardOrderIdForOrder = ({
  vitecNextProduct: product,
  vitecNextOrder
}: {
  vitecNextProduct: Product
  vitecNextOrder?: VitecNextOrder
}): string => getLeopardOrderId({ product, externalEstateId: vitecNextOrder?.estateId })

export function getLeopardOrderId(props: GetLeopardOrderIdProps): string {
  if (!props.externalEstateId) return ''
  if (!props.product) return ''

  const { externalEstateId, product } = props

  return `${externalEstateId}-${product}`
}
