import {
  BasicAssociationItems,
  BasicAssociationResultsResponse
} from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { Product } from '../../../../pure/leopard/Product'
import { BasicAssociationFields } from '../../../../pure/libs/VitecNextOrderProductBasicAssociationItemsPure'
import { getBasicAssociationFieldsFromJaguarResult } from '../../../../pure/libs/getBasicAssociationFieldsFromJaguarResult'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { upsertLeopardOrder } from '../libs/DBApiHandler'
import { useVitecNOOrder } from './QueryHooks'
import { useJaguarResultExperimental } from './useJaguarResults'
import { useLeopardOrder } from './useLeopardOrder'

export const useBasicAssociaionFieldsState = (vitecNextOrderId: string) => {
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)
  const vitecNextProduct = Product.BASIC_ASSOCIATION

  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderId({ externalEstateId: vitecNextOrder?.estateId, product: vitecNextProduct })
  )

  const { data: jaguarResult } = useJaguarResultExperimental<BasicAssociationResultsResponse>(leopardOrder)

  const basicAssociationFields = {
    ...getBasicAssociationFieldsFromJaguarResult(
      jaguarResult?.structured_outputs?.basic_association_info as BasicAssociationItems
    ),
    ...leopardOrder?.extra?.basicAssociationFields
  } as BasicAssociationFields

  const updateBasicAssociationFields = (fields: BasicAssociationFields) => {
    if (!leopardOrder) return

    upsertLeopardOrder({ ...leopardOrder, extra: { ...leopardOrder.extra, basicAssociationFields: fields } })
  }

  return { basicAssociationFields, updateBasicAssociationFields }
}
