import styled from '@emotion/styled'
import { SecondaryColor30 } from '../../../../pure/libs/Colors'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { BorderRadixes } from '../enums/BorderRadixes'
import Box from './Box'
import { DashboardStatusTextCreated } from './DashboardStatusTextCreated'
import { DashboardStatusValidation } from './DashboardStatusValidation'
import Loader from './Loader'
import { DEFAULT_ICON_SIZE } from './Icon'
import useIsMobile from '../hooks/useIsMobile'

export function DashboardStatus(props: { vitecNextOrder?: VitecNextOrder; isLoading?: boolean }) {
  const isMobile = useIsMobile()
  const enableStatusTextCreated = !isMobile
  const enableLabel = !isMobile

  if (props.isLoading)
    return (
      <Container fullWidth align="center" fullPadding>
        <Loader size={DEFAULT_ICON_SIZE} />
      </Container>
    )
  return (
    <Container fullWidth direction="row" align="center" fullPadding justify="space-between">
      <Box>{enableStatusTextCreated && <DashboardStatusTextCreated {...props} />}</Box>
      <Box>
        <DashboardStatusValidation {...props} enableLabel={enableLabel} />
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${SecondaryColor30};
  border-radius: ${BorderRadixes.regular};
`
