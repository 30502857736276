import { JaguarBoundingBox as BoundingBox } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'

export function isSameBoundingBox(bb1: BoundingBox, bb2: BoundingBox) {
  return (
    !!bb1 &&
    !!bb2 &&
    bb1.filename === bb2.filename &&
    bb1.page === bb2.page &&
    bb1.points.every((v, i) => v.x === bb2.points[i]?.x && v.y === bb2.points[i]?.y)
  )
}

export function isSameBoundingBoxDeprecated({
  highlightedBoundingBox,
  boundingBox
}: {
  highlightedBoundingBox?: BoundingBox
  boundingBox: BoundingBox
}) {
  if (!highlightedBoundingBox) return false
  const { points = [], page } = highlightedBoundingBox
  return (
    (!highlightedBoundingBox.filename || boundingBox.filename?.includes(highlightedBoundingBox.filename)) &&
    !!page &&
    page === boundingBox.page &&
    points.length > 0 &&
    points.every((v, i) => v.x === boundingBox.points[i]?.x && v.y === boundingBox.points[i]?.y)
  )
}

export function getAreaOfBoundingBox(boundingBox?: BoundingBox) {
  const points = boundingBox?.points || []
  if (points.length < 4) return 0
  const width = points[2]?.x - points[0]?.x
  const height = points[2]?.y - points[0]?.y
  return width * height
}
