import { AreGazelleProductsEnabledResponse } from '../../../../pure/leopard/AreGazelleProductsEnabledResponse'
import { LiveProductNames } from '../../../../pure/leopard/Product'
import { JaguarEstate } from '../../../../pure/types/JaguarEstate'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { VitecNextEstate } from '../../../../pure/types/VitecNextEstate'
import { VitecNextProductInfoItems, validateVitecNextProductInfoItem } from '../components/VitecNextProductInfoItems'
import { DrawerContext, useDrawerContext } from '../components/useDrawerContext'
import { useLatestLeopardOrders } from '../components/useLatestLeopardOrders'
import {
  VitecNextItemsValidationArgs,
  VitecNextProductInfoItem,
  VitecNextProductInfoItemValidationItem
} from '../types/VitecNextProductTypes'
import { useJaguarEstate, useVitecNextEstate } from './QueryHooks'
import { useAreProductsEnabledCache } from './useIsProductEnabled'
import { getJaguarResultsForLeopardOrderAndEstate } from './useJaguarResults'
import { useVitecNextEstateForOrder } from './useVitecNextEstateForOrder'

export const useVitecNextValidationItems = ({
  vitecNextOrder
}: {
  vitecNextOrder?: VitecNextOrder
}): { data: VitecNextProductInfoItemValidationItem[]; isLoading: boolean } => {
  const drawerContext = useDrawerContext('ad_validation')
  const { data: leopardOrders = [] } = useLatestLeopardOrders(vitecNextOrder)

  const { data: fireStoreEstate } = useVitecNextEstate(vitecNextOrder?.estateId || '')

  const { data: jaguarEstate } = useJaguarEstate(vitecNextOrder?.estateId || '')

  const { data: vitecNextEstate } = useVitecNextEstateForOrder({
    legacyVitecNextOrderId: vitecNextOrder?.orderId || '',
    externalEstateId: vitecNextOrder?.estateId || '',
    useCache: false
  })

  const estate = vitecNextEstate || fireStoreEstate

  const { data: areProductsEnabled, isLoading: isLoadingProducts } = useAreProductsEnabledCache({
    vitecNextOrderId: vitecNextOrder?.orderId || '',
    externalEstateId: vitecNextOrder?.estateId || ''
  })

  const isLoading = isLoadingProducts || false

  const validationItems = getValidationItems(
    leopardOrders,
    jaguarEstate,
    vitecNextOrder,
    estate,
    areProductsEnabled,
    drawerContext
  )

  return { data: validationItems, isLoading }
}

function getValidationItems(
  leopardOrders: LeopardOrder[],
  jaguarEstate: JaguarEstate | undefined,
  vitecNextOrder: VitecNextOrder | undefined,
  estate: VitecNextEstate | undefined,
  areProductsEnabled: AreGazelleProductsEnabledResponse | undefined,
  drawerContext: DrawerContext
): VitecNextProductInfoItemValidationItem[] {
  if (!vitecNextOrder) return []
  if (!estate?.estateId) return []

  const rows = LiveProductNames.map((vitecNextProduct) => ({
    leopardOrder: leopardOrders.find((order) => order?.product === vitecNextProduct),
    vitecNextProduct
  }))

  const jaguarResponses = getJaguarResultsForLeopardOrderAndEstate({ leopardOrders, jaguarEstate })

  return rows
    .map((row): VitecNextProductInfoItemValidationItem[] => {
      const { vitecNextProduct } = row
      const leopardOrder = leopardOrders.find((order) => order?.product === vitecNextProduct)
      const results = jaguarResponses.find((r) => r?.request_id === leopardOrder?.requestId)

      const infoItem: VitecNextProductInfoItem = VitecNextProductInfoItems[vitecNextProduct]

      if (!infoItem) return []
      if (!results) return []

      const { validate } = infoItem
      const props: VitecNextItemsValidationArgs = {
        vitecNextOrder,
        vitecNextProduct,
        estate,
        results,
        isVitecNextProductEnabled: areProductsEnabled?.[vitecNextProduct],
        drawerContext
      }

      const defaultItems: VitecNextProductInfoItemValidationItem[] = validateVitecNextProductInfoItem?.(props) || []

      if (defaultItems.length > 0) return defaultItems

      return validate?.(props) || []
    })
    .flat()
}
