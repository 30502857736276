import { VitecNextEstate } from '../types/VitecNextEstate'
import {
  BasicAssociationFields,
  VitecNextOrderProductBasicAssociationItemsPure,
  BasicAssociationResponseItemsKey,
  VitecNextOrderProductBasicAssociationItemPure,
  BasicAssociationResponseItemsKeysNotInJaguarArray
} from './VitecNextOrderProductBasicAssociationItemsPure'

export function getValidatableBasicAssociationEntriesEntries({
  estate,
  fields
}: {
  estate?: VitecNextEstate
  fields: BasicAssociationFields
}) {
  return Object.entries(VitecNextOrderProductBasicAssociationItemsPure)
    .filter(([k]) => VitecNextOrderProductBasicAssociationItemsPure[k].enabled !== false)
    .filter((b) => !BasicAssociationResponseItemsKeysNotInJaguarArray.some((r) => r === b[0]))
    .filter(([k, v]) => v.getValueFromEstate && !!fields?.[k]?.value)
}

export function isValidBasicAssociationValue({
  fields,
  key,
  fieldTemplate,
  estate
}: {
  fields: BasicAssociationFields
  key: BasicAssociationResponseItemsKey
  fieldTemplate: VitecNextOrderProductBasicAssociationItemPure
  estate?: VitecNextEstate
}) {
  const fieldValue = fields?.[key]?.value?.toString()
  const esetateValue = fieldTemplate.getValueFromEstate?.(estate)?.toString()
  if (!fieldValue || !esetateValue) return false
  if (!!fieldTemplate.vitecNextImportDoubleType) return Math.abs(parseFloat(esetateValue) - parseFloat(fieldValue)) < 1
  return esetateValue === fieldValue
}
