import { PDFPageProxy } from 'pdfjs-dist'
import React, { useEffect, useRef } from 'react'
import { Yellow25, Yellow50 } from '../../../../pure/libs/Colors'
import Box from './Box'
import { PdfPageHighlight, PdfPageHighlightData } from './PdfPageHighlight'
import { DrawerState } from './useDrawerContext'
import { JaguarBoundingBox as BoundingBox } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { getAreaOfBoundingBox } from '../libs/BoundingBoxHelper'

export interface Props {
  pdfPage?: PDFPageProxy
  boundingBoxes?: BoundingBox[]
  drawerState: DrawerState
  pageIndex?: number
}

export const PdfPage = (props: Props) => {
  const { pdfPage, boundingBoxes, drawerState, pageIndex } = props
  const containerRef = useRef<HTMLDivElement>(null)

  const [pageHighlights, setPageHighlights] = React.useState<PdfPageHighlightData[]>([])
  const [context, setContext] = React.useState<CanvasRenderingContext2D>()

  useEffect(() => {
    const renderPdf = async () => {
      if (!containerRef.current) return
      if (!pdfPage) return

      const container = containerRef.current

      const viewport = pdfPage.getViewport({ scale: 1 })

      // Create a canvas for the page
      const canvas = document.createElement('canvas')
      canvas.width = viewport.width
      canvas.height = viewport.height
      container.appendChild(canvas)

      const ctx = canvas.getContext('2d')
      if (!ctx) return

      // ctx.drawImage(canvas, x, y, width, height, 0, 0, width, height)

      await pdfPage.render({
        canvasContext: ctx,
        viewport
      }).promise

      setContext(ctx)
    }

    renderPdf()
  }, [])

  useEffect(() => {
    if (!context) return
    if (!pageIndex) return
    if (!boundingBoxes) return
    const canvas = context.canvas
    const aspectRatio = canvas.width / canvas.height

    const pageHighlights: PdfPageHighlightData[] = boundingBoxes
      .filter((boundingBox) => boundingBox.page === pageIndex)
      .map((boundingBox, i, arr) => {
        const [p0, p1, p3, p4] = boundingBox.points || []
        const x0 = p0.x
        const y0 = p0.y
        const x1 = p3.x
        const y1 = p3.y

        context.fillStyle = Yellow50
        const drawerWidth = drawerState.isOpen ? drawerState.openWidth : drawerState.closeWidth
        const scaleX = drawerWidth / boundingBox.layout_width
        const scaleY = drawerWidth / aspectRatio / boundingBox.layout_height
        const x = x0 * scaleX
        const y = y0 * scaleY
        const width = (x1 - x0) * scaleX
        const height = (y1 - y0) * scaleY
        // const backgroundColor = getAreaOfBoundingBox(boundingBox) > 500000 ? Yellow25 : Yellow25
        const backgroundColor = Yellow25
        return { style: { left: x, top: y, width, height, backgroundColor }, boundingBox }
      })

    setPageHighlights(pageHighlights)
  }, [context, boundingBoxes, pageIndex, drawerState])

  return (
    <Box ref={containerRef} fullWidth position="relative">
      {pageHighlights?.map((pdfPageHighlight, i) => (
        <PdfPageHighlight pdfPageHighlight={pdfPageHighlight} drawerState={drawerState} key={i} />
      ))}
    </Box>
  )
}
