import { as } from 'vitest/dist/chunks/reporters.C4ZHgdxQ'
import { JaguarResultResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { Product } from '../../../../pure/leopard/Product'
import { JaguarEstate } from '../../../../pure/types/JaguarEstate'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { useJaguarEstate } from './QueryHooks'
import { ReducerState } from './useFirestoreQueryHelper'

export function useJaguarResultForVitecNextOrderDeprecated<T extends JaguarResultResponse>({
  vitecNextOrder,
  vitecNextProduct
}: {
  vitecNextOrder?: VitecNextOrder
  vitecNextProduct: Product
}): ReducerState<T> {
  const q = useJaguarEstate(vitecNextOrder?.estateId || '')
  const product = mapProduct(vitecNextProduct)
  if (!product) return q as unknown as ReducerState<T>
  const data = q.data?.workflow_history?.[product]?.findLast((_) => true)
  return { ...q, data } as ReducerState<T>
}

export function useJaguarResultExperimental<T extends JaguarResultResponse>(l?: LeopardOrder): ReducerState<T> {
  const q = useJaguarEstate(l?.externalEstateId)

  const data = getJaguarResultResponse({ jaguarEstate: q.data, leopardOrder: l })
  const query = { ...q, data } as ReducerState<T>
  return query
}

export function getJaguarResultsForLeopardOrderAndEstate({
  leopardOrders,
  jaguarEstate
}: {
  leopardOrders: LeopardOrder[]
  jaguarEstate?: JaguarEstate
}): (JaguarResultResponse | undefined)[] {
  return leopardOrders.map((leopardOrder) => getJaguarResultResponse({ jaguarEstate, leopardOrder }))
}

function getJaguarResultResponse({
  jaguarEstate,
  leopardOrder
}: {
  jaguarEstate?: JaguarEstate
  leopardOrder?: LeopardOrder
}): JaguarResultResponse | undefined {
  const product = mapProduct(leopardOrder?.product)
  if (!jaguarEstate || !leopardOrder || !product) return undefined
  return jaguarEstate?.workflow_history?.[product]?.find((w) => w.request_id === leopardOrder.requestId)
}

const mapProduct = (product?: Product): keyof JaguarEstate['workflow_history'] | undefined => {
  switch (product) {
    case Product.BASIC_ASSOCIATION:
      return 'basic-association'
    case Product.CONSTRUCTION_INFO:
      return 'construction-method'
    case Product.CONSTRUCTION_INFO_EXPERIMENTAL:
      return 'construction-method'
    case Product.AREA_DESCRIPTION:
      return 'area-description'
    case Product.IMAGE_CAPTIONS:
      return 'image-captions'
    case Product.SELF_DECLERATION:
      return 'self-declaration'
    case Product.DESCRIPTIONS:
      return 'descriptions'
  }

  return undefined
}
