import { JaguarBoundingBox as BoundingBox } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { isSameBoundingBoxDeprecated } from './BoundingBoxHelper'
import { DrawerState } from '../components/useDrawerContext'

// TODO WRITE TEST, should show bounding box if no bounding boxes are highlighted
// TODO WRITE TEST, should show bounding box if bounding box is highlighted
export function shouldShowBoundingBox(drawerState: DrawerState, boundingBox: BoundingBox) {
  return (
    !drawerState.highlightedField ||
    drawerState.highlightedField?.bounding_boxes.some((highlightedBoundingBox) =>
      isSameBoundingBoxDeprecated({ boundingBox, highlightedBoundingBox })
    )
  )
}
