import { BasicAssociationResultsResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { getBasicAssociationFieldsFromJaguarResult } from '../../../../pure/libs/getBasicAssociationFieldsFromJaguarResult'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import { useJaguarResultForVitecNextOrderDeprecated } from '../hooks/useJaguarResults'
import { HardCodedTexts, Texts } from '../libs/Texts'
import { shouldShowBasicAssociationField } from '../libs/VitecNextOrderProductBasicAssociationHelper'
import {
  BasicAssociationKeysAll,
  VitecNextOrderProductBasicAssociationItems
} from '../libs/VitecNextOrderProductBasicAssociationItems'
import Box from './Box'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'

export const DashboardListItemDetailsBasicAssociationComponent = (props: VitecNextOrderProductProps) => {
  const { vitecNextOrderId, vitecNextProduct } = props
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)

  const { data: jaguarResult } = useJaguarResultForVitecNextOrderDeprecated<BasicAssociationResultsResponse>({
    vitecNextOrder,
    vitecNextProduct
  })

  const basicAssociationItems = jaguarResult?.structured_outputs?.basic_association_info

  const basicAssociationFields = basicAssociationItems
    ? getBasicAssociationFieldsFromJaguarResult(basicAssociationItems)
    : undefined

  return (
    <Box fullWidth>
      {basicAssociationFields &&
        BasicAssociationKeysAll.filter((itemKey) =>
          shouldShowBasicAssociationField({ itemKey, basicAssociationFields })
        ).map((itemKey, i) => {
          const { value } = basicAssociationFields[itemKey]
          const item = VitecNextOrderProductBasicAssociationItems[itemKey]

          if (!value) return null

          return (
            <Box top fullWidth key={i} spacing={tinySpacing}>
              <Box left spacing={smallSpacing} fullWidth>
                <FigmaText styleTextKey={HardCodedTexts.cssBaItemHeader} textKey={item.textKey} />
                <Box>
                  <FigmaTextWithLinebreaks textKey={Texts.BasicAssociationBaAnimal} text={value} />
                </Box>
              </Box>
            </Box>
          )
        })}
    </Box>
  )
}
