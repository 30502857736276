import dayjs from 'dayjs'
import React from 'react'
import { getDebugInfoAddress } from '../../../../pure/libs/getDebugInfoAddress'
import { bigSpacing, reallyBigSpacing, tinySpacing } from '../enums/Spacings'
import { useVitecNOOrder, useVitecNextEstate } from '../hooks/QueryHooks'
import useAppStateSession from '../hooks/useAppStateSession'
import useIsMobile from '../hooks/useIsMobile'
import { useAreGazelleProductsEnabled } from '../hooks/useIsProductEnabled'
import { DASHBOARD_MIN_WIDTH } from '../libs/HardCodedSizes'
import { HardCodedTexts, Texts } from '../libs/Texts'
import Box from './Box'
import { DashboardGrid } from './DashboardGrid'
import { DefaultDashboardLayout } from './DashboardLayoutSelectionButton'
import { DashboardList } from './DashboardList'
import { DashboardPreviewDeprecated } from './DashboardPreviewDeprecated'
import { DashboardStatus } from './DashboardStatus'
import FigmaText from './FigmaText'
import { Layout } from './Layout'
import { LayoutLoadingPage } from './LoadingPage'
import MenuVitecNext from './MenuVitecNext'
import { WithAdValidationPdfDrawer } from './WithAdValidationPdfDrawer'
import { useDrawerContext } from './useDrawerContext'

export const Dashboard: React.FC<{ vitecNextOrderId: string }> = ({ vitecNextOrderId }) => {
  const drawerContext = useDrawerContext('ad_validation')
  const isMobile = useIsMobile()
  const enablePreview = !isMobile
  const now = dayjs().format()
  const dashboardWidth = Math.min(window.innerWidth, DASHBOARD_MIN_WIDTH)

  const { data: vitecNextOrder, isLoading: isLoadingOrder } = useVitecNOOrder(vitecNextOrderId)
  const { data: estate } = useVitecNextEstate(vitecNextOrder?.estateId)
  const { state } = useAppStateSession()
  const { dashboardLayout = DefaultDashboardLayout } = state

  const { isLoading: isLoadingAreProductsEnabled } = useAreGazelleProductsEnabled({
    vitecNextOrderId,
    externalEstateId: vitecNextOrder?.estateId
  })

  const isLoading = isLoadingOrder || isLoadingAreProductsEnabled

  if (!vitecNextOrder) return <LayoutLoadingPage />

  let address: string | undefined = getDebugInfoAddress(estate)
  if (address.includes('Eksempel')) address = undefined

  return (
    <Layout enableFooter={false} MenuComponent={MenuVitecNext} enableRightBottomImage={false}>
      <WithAdValidationPdfDrawer vitecNextOrderId={vitecNextOrderId} drawerContext={drawerContext}>
        <Box fullWidth align="center" spacing={bigSpacing} top bottom>
          <Box width={`${dashboardWidth}px`} left right spacing={isMobile ? tinySpacing : reallyBigSpacing}>
            <Box>
              <FigmaText textKey={HardCodedTexts.orderViewHeader} text={address} />
            </Box>
            <Box top fullWidth direction="row" align="center" justify="space-between">
              <Box>
                <FigmaText textKey={Texts.dashboardFeaturesHeader} />
              </Box>
              {enablePreview && (
                <Box>
                  <DashboardPreviewDeprecated />
                </Box>
              )}
            </Box>
            <Box top fullWidth>
              <DashboardStatus vitecNextOrder={vitecNextOrder} isLoading={isLoading} />
            </Box>
            <Box fullWidth top>
              {dashboardLayout === 'grid' && <DashboardGrid vitecNextOrder={vitecNextOrder} now={now} />}
              {dashboardLayout === 'list' && <DashboardList vitecNextOrder={vitecNextOrder} now={now} />}
            </Box>
          </Box>
        </Box>
      </WithAdValidationPdfDrawer>
    </Layout>
  )
}
