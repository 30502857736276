import styled from '@emotion/styled'
import { useEffect, useRef } from 'react'
import { BuildingComponent } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { Yellow50 } from '../../../../pure/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'
import { HIGHLIGHTED_TOP_ABSOLUTE_POSITION } from '../libs/HardCodedSizes'
import Box from './Box'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'

export type ConstructionMethodItem = { id: string; text: string; field?: BuildingComponent; pageNumber?: string }

export function VitecNextOrderProductConstrucationInfoItem({
  text,
  item,
  isSelected,
  onClickHighlight
}: {
  text: string
  item?: ConstructionMethodItem
  isSelected: boolean
  onClickHighlight: (field: ConstructionMethodItem) => unknown
}) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isSelected) ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [isSelected])

  const onClick = item ? () => onClickHighlight(item) : undefined

  return (
    <TextContainer
      isSelected={isSelected}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
      position="relative"
    >
      <Box ref={ref} style={{ position: 'absolute', top: -HIGHLIGHTED_TOP_ABSOLUTE_POSITION }} />
      <FigmaTextWithStyle
        Component={FigmaTextWithLinebreaks as any}
        separator={<span />}
        separatorWhenEmptyContent={<br />}
        type="body1"
        text={text}
      />
    </TextContainer>
  )
}

const TextContainer = styled(Box)<{ isSelected?: boolean }>`
  display: inline-block;
  background-color: ${({ isSelected }) => (isSelected ? Yellow50 : 'transparent')};
  border-radius: ${BorderRadixes.regular};
`
