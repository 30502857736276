import { VitecNextProductInfoItem } from '../types/VitecNextProductTypes.js'
import Box from './Box.js'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems.js'

import dayjs from 'dayjs'
import { Product } from '../../../../pure/leopard/Product.js'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId.js'
import { useVitecNOOrder } from '../hooks/QueryHooks.js'
import { useLeopardOrder } from '../hooks/useLeopardOrder.js'
import { VitecNextOrderItemStatus } from './VitecNextOrderItemStatus.js'

type VitecNextOrderItemProps = {
  vitecNextProduct: Product
  vitecNextOrderId: string
}

export const DashboardListItemDetailsStatus = (props: VitecNextOrderItemProps) => {
  const { vitecNextProduct, vitecNextOrderId } = props

  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)

  if (!vitecNextOrder) return null

  return (
    <Box fullWidth align="center">
      <Box width="30%">
        <VitecNextOrderItemStatus
          vitecNextOrder={vitecNextOrder}
          vitecNextProduct={vitecNextProduct}
          now={dayjs().format()}
        />
      </Box>
    </Box>
  )
}
