import { useEffect } from 'react'
import { HighlightedField } from '../../../../pure/libs/VitecNextOrderProductBasicAssociationItemsPure'
import useAppState from '../hooks/useAppState'
import { popHighlightedHistoryState } from '../libs/HistoryStateHelper'

export type DrawerContext = {
  drawerState: DrawerState
  close: () => void
  enable: () => void
  disable: () => void
  open: () => void
  highlight: (highlightedField: HighlightedField) => void
  type: DrawerTypes
}
export type DrawerState = {
  isOpen: boolean
  openWidth: number
  closeWidth: number
  highlightedField?: HighlightedField
  enabled?: boolean
}

const DEFAULT_STATE: DrawerState = {
  isOpen: false,
  openWidth: window.innerWidth / 2,
  closeWidth: window.innerWidth / 10,
  enabled: true
}

export const useDrawerContext = (type: DrawerTypes): DrawerContext => {
  const appContext = useAppState()
  const defaultState = { ...DEFAULT_STATE, ...DrawerInfo[type] }
  const openState = { ...defaultState, isOpen: true }
  const closedState = { ...defaultState, isOpen: false }
  const drawerState = appContext.state.drawerContexts?.[type]?.drawerState || defaultState

  const setState = (drawerState: DrawerState) =>
    appContext.setState((state) => {
      return {
        ...state,
        drawerContexts: {
          ...state.drawerContexts,
          [type]: { ...state.drawerContexts?.[type], drawerState }
        }
      }
    })

  const open = () => setState(openState)
  const close = () => {
    popHighlightedHistoryState()
    setState(closedState)
  }
  const enable = close
  const disable = () => setState({ ...DEFAULT_STATE, enabled: false })
  const highlight = (highlightedField: HighlightedField) => {
    if (!drawerState.highlightedField) setState(openState)
    setTimeout(() => setState({ ...openState, highlightedField }), 100)
  }

  useEffect(() => {
    if (!drawerState.enabled) enable()
    return () => {
      disable()
    }
  }, [])

  return { drawerState, open, close, enable, disable, highlight, type }
}

export type DrawerTypes =
  | 'ad_validation'
  | 'basic_association'
  | 'self_declaration'
  | 'construction_info'
  | 'descriptions'

const DrawerInfo: {
  [property in DrawerTypes]: {
    openWidth?: number
    closeWidth?: number
  }
} = {
  ad_validation: { closeWidth: 0 },
  basic_association: {},
  self_declaration: {},
  construction_info: {},
  descriptions: {}
}
