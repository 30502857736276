import { smallSpacing } from '../enums/Spacings.js'
import { VitecNextProductInfoItem } from '../types/VitecNextProductTypes.js'
import Box from './Box.js'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems.js'

import dayjs from 'dayjs'
import { Product } from '../../../../pure/leopard/Product.js'
import { useVitecNOOrder } from '../hooks/QueryHooks.js'
import { useAreGazelleProductsEnabled, useIsProductEnabled } from '../hooks/useIsProductEnabled.js'
import { VitecNextOrderItemButton } from './VitecNextOrderItemButton.js'
import { useLeopardOrder } from '../hooks/useLeopardOrder.js'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId.js'
import { isNotStarted } from '../libs/JaguarHelper.js'
import { useJaguarResultExperimental } from '../hooks/useJaguarResults.js'

type VitecNextOrderItemProps = {
  vitecNextProduct: Product
  externalEstateId: string
  vitecNextOrderId: string
}

export const DashboardListItemDetailsEmptyState = (props: VitecNextOrderItemProps) => {
  const { vitecNextProduct, vitecNextOrderId } = props
  const vitecNextProductInfoItem: VitecNextProductInfoItem = VitecNextProductInfoItems[vitecNextProduct]
  const { buttons } = vitecNextProductInfoItem

  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)

  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderId({ externalEstateId: vitecNextOrder?.estateId, product: vitecNextProduct })
  )

  const { data: result } = useJaguarResultExperimental(leopardOrder)

  const { data: isProductEnabled } = useIsProductEnabled(props)

  if (!vitecNextOrder) return null

  return (
    <Box fullWidth align="center">
      <Box>
        <Box pointer alignSelf="flex-start" direction="row" align="center" fullWidth>
          {buttons.map((button, i) => (
            <Box key={i} top spacing={smallSpacing}>
              <VitecNextOrderItemButton
                button={button}
                vitecNOOrder={vitecNextOrder}
                vitecNextProduct={vitecNextProduct}
                isProductEnabled={isProductEnabled}
                index={i}
                now={dayjs().format()}
                isLoading={!!leopardOrder && !leopardOrder.error && !result} /** When there is a result **/
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
