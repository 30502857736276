import { collection, query, where } from 'firebase/firestore'
import { FeatureFlagEnvironment, FeatureFlags } from '../../../../pure/enums/FeatureFlags.js'
import Collections from '../../../../pure/libs/Collections.js'
import { JaguarEstate } from '../../../../pure/types/JaguarEstate.js'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder.js'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes.js'
import { Organization, User } from '../../../../pure/types/types.js'
import config from '../Config.js'
import { db } from '../libs/Firebase.js'
import { getObjectQuery } from '../libs/FirebaseStorageClientForWebApp.js'
import { useFirestoreQuery } from './useFirestoreQuery.js'
import { Product } from '../../../../pure/leopard/Product.js'
import { VitecNextEstate } from '../../../../pure/types/VitecNextEstate.js'

// use useUser instead
export const _useUser = (id: string) =>
  useFirestoreQuery<User>(getObjectQuery(id, Collections.USERS), { fnName: 'useUser', enabled: !!id })

// Single Objects
export const useOrganization = (organizationId = '') =>
  useFirestoreQuery<Organization>(getObjectQuery(organizationId as string, Collections.ORGANIZATIONS), {
    fnName: 'useOrganization',
    enabled: !!organizationId
  })

export const useVitecNOOrder = (id = '') =>
  useFirestoreQuery<VitecNextOrder>(getObjectQuery(id as string, Collections.VITEC_NO_ORDER), {
    fnName: 'useVitecNoOrder',
    enabled: !!id
  })

export const useJaguarEstate = (estateId = '') =>
  useFirestoreQuery<JaguarEstate>(getObjectQuery(estateId as string, Collections.JAGUAR_ESTATES), {
    fnName: 'useJaguarEstate',
    enabled: !!estateId
  })

// this is unrelieable, might not have the latest data
export const useVitecNextEstate = (estateId = '') =>
  useFirestoreQuery<VitecNextEstate>(getObjectQuery(estateId as string, Collections.VITEC_NEXT_ESTATES), {
    fnName: 'useVitecNextEstate',
    enabled: !!estateId
  })

export const _useLeopardOrder = (id = '') =>
  useFirestoreQuery<LeopardOrder>(getObjectQuery(id || 'no-id', Collections.LEOPARD_ORDERS), {
    fnName: 'useLeopardOrder',
    enabled: !!id
  })

export const useFeatureFlags = (id: FeatureFlagEnvironment = config.name) =>
  useFirestoreQuery<FeatureFlags>(getObjectQuery(id, Collections.FEATURE_FLAGS), {
    fnName: 'useFeatureFlags'
  })

export const _useLeopardOrdersForEstate = (estateId: string = 'no-id') =>
  useFirestoreQuery<LeopardOrder[]>(
    query(collection(db, Collections.LEOPARD_ORDERS), where('externalEstateId', '==', estateId)),
    {
      fnName: 'useFeatureFlags',
      enabled: !!estateId,
      mapFn: (l) => ({ ...l, product: l.product.replace('_EXPERIMENTAL', '') as Product }) // TODO: Remove this in one month
    }
  )
