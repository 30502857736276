import dayjs from 'dayjs'
import { Product } from '../../../../pure/leopard/Product'
import { createUniqueId } from '../../../../pure/libs/Common'
import { getLeopardOrderIdForOrder } from '../../../../pure/libs/getLeopardOrderId'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { VitecNextProductInfoItems } from '../components/VitecNextProductInfoItems'
import { State } from '../hooks/useAppState'
import { tryCreateJaguarOrder } from '../libs/CloudFunctionsApiHandler'
import { upsertLeopardOrder } from '../libs/DBApiHandler'
import { getFeatureFlagsFromUrl } from './Common'

export type CreateLeopardOrderArgs = {
  vitecNextOrder: VitecNextOrder
  vitecNextProduct: Product
  requestId?: string
  state: State
  enablePreview: boolean
  extra?: LeopardOrder['extra']
}

export const createLeopardOrder = async ({
  vitecNextOrder,
  vitecNextProduct,
  requestId = createUniqueId(),
  enablePreview,
  state,
  extra
}: CreateLeopardOrderArgs) => {
  const opts = VitecNextProductInfoItems[vitecNextProduct].opts || {}
  opts.featureFlags = getFeatureFlagsFromUrl()

  const id = getLeopardOrderIdForOrder({ vitecNextOrder, vitecNextProduct })
  const leopardOrder: LeopardOrder = {
    _id: id,
    id,
    externalEstateId: vitecNextOrder.estateId,
    requestId,
    createdAt: dayjs().format(),
    enablePreview,
    finishedAt: null,
    product: vitecNextProduct,
    extra,
    metaData: {
      opts
    }
  }

  await upsertLeopardOrder(leopardOrder)

  await tryCreateJaguarOrder(
    {
      requestId,
      externalEstateId: vitecNextOrder.estateId,
      legacyVitecNextOrderId: vitecNextOrder.orderId,
      vitecNextProduct,
      extra,
      opts
    },
    state
  )

  return vitecNextOrder
}
