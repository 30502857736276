import { isProcessingUpload } from '../libs/VitecNextOrderItemHelper'
import { Upload } from '../types/JaguarTypes'
import { JaguarResultResponse } from './JaguarExperimentalTypesDerived'
import { Product } from './Product'

type VitexNextProductPureInfoItem = {
  enableSendOnDoneEmail?: boolean
  vitecNextProductExperimental?: Product
}

export const VitecNextProductPureInfoItems: {
  [key in Product]: VitexNextProductPureInfoItem
} = {
  [Product.AREA_DESCRIPTION]: {},
  [Product.DESCRIPTIONS]: {
    enableSendOnDoneEmail: true
  },
  [Product.IMAGE_CAPTIONS]: {
    enableSendOnDoneEmail: true
  },
  [Product.CONSTRUCTION_INFO]: {
    vitecNextProductExperimental: Product.CONSTRUCTION_INFO_EXPERIMENTAL
  },
  [Product.CONSTRUCTION_INFO_EXPERIMENTAL]: {},
  [Product.BASIC_ASSOCIATION]: {
    enableSendOnDoneEmail: true
  },
  [Product.SELF_DECLERATION]: {}
}

export function shouldShowLongWaitMessage({
  results,
  vitecNextProduct
}: {
  results?: JaguarResultResponse
  vitecNextProduct: Product
}) {
  return shouldSendOnUploadDoneInfoEmail({ vitecNextProduct }) && isProcessingUpload(results)
}

export function shouldSendOnUploadDoneInfoEmail({ vitecNextProduct }: { vitecNextProduct: Product }) {
  return VitecNextProductPureInfoItems[vitecNextProduct]?.enableSendOnDoneEmail
}
