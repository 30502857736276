import { Product } from '../../../../pure/leopard/Product'
import { LeopardOrder } from '../../../../pure/types/LeopardOrder'
import { _useLeopardOrder } from './QueryHooks'
import { ReducerState } from './useFirestoreQueryHelper'

export const useLeopardOrder = (id = ''): ReducerState<LeopardOrder> => {
  const q1 = _useLeopardOrder(id)
  const q2 = _useLeopardOrder(id + '_EXPERIMENTAL') // TODO: remove this in one month

  if (!q1.data?.createdAt && !q2.data?.createdAt) return { ...q1, data: undefined }

  return {
    ...q2,
    ...q1,
    data: {
      ...q2.data,
      ...q1.data,
      product: q1.data?.product || q2.data?.product?.replace('_EXPERIMENTAL', '')
    } as LeopardOrder
  }
}
