import styled from '@emotion/styled'
import { FeatureFlag } from '../../../../pure/enums/FeatureFlags'
import { Product } from '../../../../pure/leopard/Product'
import Colors from '../../../../pure/libs/Colors'
import { DescriptionTypes } from '../../../../pure/libs/VitecNextOrderProductDescriptionsItemsPure'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import { useDescriptionsFieldsState } from '../hooks/useDescriptionsFieldsState'
import { getGsUrlForLeopardOrder } from '../hooks/useDownloadUrl'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import { useJaguarResultExperimental } from '../hooks/useJaguarResults'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { DescriptionKeysAll } from '../libs/VitecNextOrderProductDescriptionsItems'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { JsonView } from './JsonView'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import { VitecNextOrderProductButtonsDeprecated as VitecNextOrderProductButtons } from './VitecNextOrderProductButtonsDeprecated'
import { VitecNextOrderProductDescriptionsItem } from './VitecNextOrderProductDescriptionsItem'
import { VitecNextOrderProductHeader } from './VitecNextOrderProductHeader'
import { VitecNextOrderProductStatus } from './VitecNextOrderProductStatus'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { WithPdfDrawer } from './WithPdfDrawer'
import { useDrawerContext } from './useDrawerContext'

// http://localhost:5176/vitec-next-order/D9482551-37A6-4120-B4D6-03336FB1DF93/DESCRIPTIONS

export const VitecNextOrderProductDescriptionsExperimental = (props: VitecNextOrderProductProps) => {
  const enableDebug = useFeatureFlag(FeatureFlag.enableDebug)
  const { vitecNextOrderId, vitecNextProduct } = props
  const item = VitecNextProductInfoItems[vitecNextProduct]
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)
  const drawerContext = useDrawerContext('descriptions')

  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderId({ externalEstateId: vitecNextOrder?.estateId, product: vitecNextProduct })
  )

  const gsUrl = getGsUrlForLeopardOrder(leopardOrder)

  const { data: jaguarResult } = useJaguarResultExperimental(leopardOrder)

  const { fields, setFields } = useDescriptionsFieldsState(vitecNextOrderId)

  if (!vitecNextOrder) return <Box />
  if (!leopardOrder) return <Box />

  const successText = getFigmaText(Texts.standardNotificationUploadComplete)

  return (
    <WithPdfDrawer pdfViewerPropSets={[{ gsUrl }]} drawerContext={drawerContext}>
      <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
        <Box>
          <VitecNextOrderProductBackButton vitecNextOrderId={vitecNextOrderId} />
        </Box>
        <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
          <VitecNextOrderProductHeader
            vitecNextOrder={vitecNextOrder}
            headerTextKey={item.textKey}
            vitecNextProduct={vitecNextProduct}
          />
          <Box top>
            <FigmaImageContainer imageKey={Images.gazelleInCircle} />
          </Box>
          <Box fullWidth top spacing={smallSpacing}>
            <VitecNextOrderProductButtons
              vitecNextOrderId={vitecNextOrderId}
              vitecNextProduct={Product.DESCRIPTIONS}
              fulfillMentSuccessText={successText}
              enableCopy={false}
              /**
 * 
 * 
 *             onPostFulfill={() => success(successText)}
            onPreFulfill={async () => {
              fields &&
                (await setFields(
                  Object.entries(fields).reduce((a, [k, v]) => {
                    if (v.checked === false) return a

                    return {
                      ...a,
                      [k]: {
                        ...v,
                        fulfilledAt: null,
                        isFulfilling: true
                      } as DescriptionField
                    }
                  }, {} as DescriptionsFields)
                ))
            }}
 */
            />
            <Box fullWidth top>
              <VitecNextOrderProductStatus {...props} drawerContext={drawerContext} />
            </Box>
          </Box>
          <Box fullWidth>
            <Box fullWidth top>
              <Container fullWidth fullPadding position="relative">
                <Box direction="row" align="center">
                  <FigmaText textKey={Texts.BasicAssociationSuggestedTextHeaderNoUpload} />
                </Box>
                {DescriptionKeysAll.map((key, i) => {
                  return (
                    <Box top fullWidth key={i}>
                      <VitecNextOrderProductDescriptionsItem
                        fields={fields}
                        setFields={setFields}
                        itemKey={key as DescriptionTypes}
                        enableUpload={false}
                        vitecNextOrder={vitecNextOrder}
                        leopardOrder={leopardOrder}
                      />
                    </Box>
                  )
                })}

                {enableDebug && <JsonView data={jaguarResult || {}} />}
              </Container>
            </Box>
          </Box>
        </Box>
        <Box visibility="hidden">
          <VitecNextOrderProductBackButton vitecNextOrderId={vitecNextOrderId} />
        </Box>
      </Box>
    </WithPdfDrawer>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`
