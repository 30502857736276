import styled from '@emotion/styled'
import MaterialDrawer from '@mui/material/Drawer'
import { PropsWithChildren, useRef } from 'react'
import Colors from '../../../../pure/libs/Colors'
import { tinyBorderRadius } from '../enums/BorderRadixesDeprecated'
import { defaultSpacing, tinySpacing } from '../enums/Spacings'
import ZIndices from '../enums/Zindices'
import Box from './Box'
import Button from './Button'
import { ModalCloseIcon } from './Icons'
import { PdfViewer, PdfViewerProps } from './PdfViewer'
import { useDrawerContext } from './useDrawerContext'

export const WithPdfDrawer = (
  props: PropsWithChildren<{
    drawerContext: ReturnType<typeof useDrawerContext>
    pdfViewerPropSets: Partial<PdfViewerProps | undefined>[]
    LeftComponent?: React.FC
  }>
) => {
  const { children, drawerContext, LeftComponent = Box } = props
  const { isOpen, enabled = true, openWidth, closeWidth } = drawerContext.drawerState
  const drawerContentRef = useRef<HTMLDivElement>(null)
  const { drawerState, open, close } = drawerContext
  const width = isOpen ? openWidth : closeWidth
  const pdfViewerPropSets = (props.pdfViewerPropSets || []).filter(Boolean) as Partial<PdfViewerProps>[]

  const onClickContent = () => (drawerState.isOpen ? close() : open())

  if (!enabled || width === 0 || pdfViewerPropSets.length === 0)
    return (
      <Box fullWidth fullHeight>
        {children}
      </Box>
    )

  return (
    <>
      <MaterialDrawer
        anchor="right"
        open
        variant="persistent"
        sx={{
          '& .MuiPaper-root': {
            height: '100%',
            borderTop: `1px solid ${Colors.neutral80}`,
            borderTopLeftRadius: tinyBorderRadius,
            position: 'fixed'
          },
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
            cursor: 'pointer'
          }
        }}
      >
        <Box fullHeight direction="row">
          {LeftComponent && isOpen && (
            <LeftComponentContainer>
              <LeftComponent />
            </LeftComponentContainer>
          )}
          <Box width={`${width}px`} style={{ overflow: 'auto' }} ref={drawerContentRef} fullHeight>
            <Box
              zIndex={ZIndices.high}
              position="fixed"
              style={{
                right: defaultSpacing,
                top: defaultSpacing
              }}
              visibility={drawerState.isOpen ? 'visible' : 'hidden'}
            >
              <Box pointer>
                <Button onClick={close} spacing={tinySpacing}>
                  <ModalCloseIcon color="white" />
                </Button>
              </Box>
            </Box>
            <Box fullWidth onClick={onClickContent} pointer>
              {pdfViewerPropSets.map((pdfViewerProps, i) => (
                <PdfViewer
                  key={i}
                  index={i}
                  {...pdfViewerProps}
                  drawerContentRef={drawerContentRef}
                  drawerState={drawerState}
                  enableScrolling={pdfViewerPropSets.length === 1}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </MaterialDrawer>
      <Box fullHeight style={{ width: `calc(100% - ${drawerState.isOpen ? `${width}px` : '0px'})` }}>
        {children}
      </Box>
    </>
  )
}

const LeftComponentContainer = styled(Box)`
  border-right: 1px solid ${Colors.neutral30};
`
