import { BasicAssociationResultsResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { Product } from '../../../../pure/leopard/Product'
import { getLeopardOrderIdForOrder } from '../../../../pure/libs/getLeopardOrderId'
import { useVitecNOOrder } from './QueryHooks'
import { useDefaultPdfViewerPropSetsForBasicAssociationResults } from './useDefaultPdfViewerPropsForBasicAssociationResults'
import { useJaguarResultExperimental } from './useJaguarResults'
import { useLeopardOrder } from './useLeopardOrder'

export const usePdfViewerPropSetsForBasicAssociationResults = ({ vitecNextOrderId }: { vitecNextOrderId: string }) => {
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)
  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderIdForOrder({ vitecNextProduct: Product.BASIC_ASSOCIATION, vitecNextOrder })
  )

  const { data: jaguarResult } = useJaguarResultExperimental<BasicAssociationResultsResponse>(leopardOrder)

  const { data: pdfViewerPropSets = [] } = useDefaultPdfViewerPropSetsForBasicAssociationResults({
    leopardOrder,
    basicAssociationItems: jaguarResult?.structured_outputs?.basic_association_info
  })

  return pdfViewerPropSets
}
