import { Product } from '../../../../pure/leopard/Product'
import { VitecNextProductPureInfoItems } from '../../../../pure/leopard/VitecNextProductPureInfoItems'
import { toPx } from '../../../../pure/libs/Common'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { formatRoutePathVitecNextOrderProduct } from '../../../../pure/libs/RoutePath'
import { TextKey } from '../../../../pure/types/Antiloop'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { tinySpacing } from '../enums/Spacings'

import { useJaguarResultExperimental } from '../hooks/useJaguarResults'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { useNavigate } from '../hooks/useNavigate'
import { formatText, getFigmaText, getFigmaTextFromVariable } from '../libs/TextRepository'
import { HardCodedTexts, Texts } from '../libs/Texts'
import Box from './Box'
import FigmaText from './FigmaText'
import { InfoIcon } from './Icons'
import Tooltip from './Tooltip'

export function VitecNextOrderProductHeader(props: {
  vitecNextOrder: VitecNextOrder
  headerTextKey: TextKey
  vitecNextProduct: Product
  enableExperimentalVersion?: boolean
}) {
  const { vitecNextOrder, headerTextKey, vitecNextProduct: vitecNextProduct, enableExperimentalVersion = true } = props
  const navigate = useNavigate()

  const { vitecNextProductExperimental } = VitecNextProductPureInfoItems[vitecNextProduct]

  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderId({ externalEstateId: vitecNextOrder?.estateId, product: props.vitecNextProduct })
  )

  const { data: results } = useJaguarResultExperimental(leopardOrder)
  const hasDoneExperimentalOrder = vitecNextProductExperimental && results?.status === 'success'
  const normalVitecNextProduct = Object.entries(VitecNextProductPureInfoItems).find(
    ([k, v]) => v.vitecNextProductExperimental === vitecNextProduct
  )?.[0] as Product
  const isExperimental = !!normalVitecNextProduct
  const headerText = getFigmaText(headerTextKey)

  return (
    <Box fullWidth>
      <Box>
        <FigmaText styleTextKey={Texts.ConstructionCiHeaderText} text={headerText} />
      </Box>
      {vitecNextOrder?.address && <FigmaText textKey={Texts.dashboardFeaturesHeader} text={vitecNextOrder?.address} />}

      {hasDoneExperimentalOrder && !!vitecNextProductExperimental && enableExperimentalVersion && (
        <Box
          link
          onClick={() => {
            window.location.href = formatRoutePathVitecNextOrderProduct({
              vitecNextOrderId: vitecNextOrder.orderId,
              vitecNextProduct: vitecNextProductExperimental,
              externalEstateId: vitecNextOrder.estateId
            })
          }}
        >
          <Box direction="row" align="center">
            <Box link>
              <FigmaText textKey={HardCodedTexts.vitecNextOrderProductHeaderGoToExperimentalText} />
            </Box>
            <Box left spacing={tinySpacing} link>
              <Tooltip title={formatText(getFigmaTextFromVariable('VariableID:317:936'), [headerText])}>
                <InfoIcon
                  color={HardCodedTexts.vitecNextOrderProductHeaderExperimentalText.style.color}
                  size={toPx(HardCodedTexts.vitecNextOrderProductHeaderExperimentalText.style.fontSize)}
                />
              </Tooltip>
            </Box>
          </Box>
        </Box>
      )}
      {!!isExperimental && (
        <Box
          link
          onClick={() =>
            (window.location.href = formatRoutePathVitecNextOrderProduct({
              vitecNextOrderId: vitecNextOrder.orderId,
              vitecNextProduct: normalVitecNextProduct,
              externalEstateId: vitecNextOrder.estateId
            }))
          }
        >
          <FigmaText textKey={HardCodedTexts.vitecNextOrderProductHeaderExperimentalText} />
        </Box>
      )}
    </Box>
  )
}
