import { VitecNextEstate } from '../types/VitecNextEstate'

export function getDebugInfoAddress(estate?: VitecNextEstate) {
  if (!estate) return ''
  return getEstateStreeetAddress(estate).trim()
}

export function getEstateStreeetAddress(estate: VitecNextEstate) {
  if (!estate.address) return ''
  return `${estate.address?.streetAdress} ${estate.address?.apartmentNumber || ''}`
}

export function getEstateFullAddress(estate: VitecNextEstate) {
  if (!estate.address) return ''
  return `${estate.address?.streetAdress} ${estate.address?.apartmentNumber || ''} ${estate.address?.zipCode || ''} ${
    estate.address?.city || ''
  }`
}
