import { getDownloadURL, ref } from 'firebase/storage'
import { PDFPageProxy, getDocument } from 'pdfjs-dist'
import React, { useEffect } from 'react'
import { JaguarBoundingBox as BoundingBox } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { getArray } from '../../../../pure/libs/Common'
import { storage } from '../libs/Firebase'
import Box from './Box'
import Loader from './Loader'
import { PdfPage } from './PdfPage'
import { DrawerState } from './useDrawerContext'

export interface PdfViewerProps {
  gsUrl?: string | null
  boundingBoxes?: BoundingBox[]
  drawerState: DrawerState
  drawerContentRef: React.RefObject<HTMLDivElement>
  enableScrolling?: boolean
  index: number
}

export const PdfViewer = (props: PdfViewerProps) => {
  const { gsUrl, boundingBoxes, drawerState, index } = props
  const [pages, setPages] = React.useState<PDFPageProxy[]>([])
  const [loading, setLoading] = React.useState(true)

  useEffect(() => {
    if (!gsUrl) return

    getDownloadURL(ref(storage, gsUrl))
      .then((pdfUrl) => getDocument(pdfUrl).promise)
      .then((pdf) => Promise.all(getArray(pdf.numPages).map((page) => pdf.getPage(page + 1))))
      .then((pages) => setPages(pages))
      .finally(() => setLoading(false))
  }, [gsUrl])

  return (
    <Box fullWidth>
      {index === 0 && loading && (
        <Box fullPadding fullWidth fullHeight align="center" justify="center">
          <Loader />
        </Box>
      )}
      {pages.map((page, i) => {
        return (
          <PdfPage
            key={i + 1}
            pdfPage={page}
            boundingBoxes={boundingBoxes}
            drawerState={drawerState}
            pageIndex={i + 1}
          />
        )
      })}
    </Box>
  )
}
