import { getLeopardOrderIdForOrder } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import { useJaguarResultExperimental } from '../hooks/useJaguarResults'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { useSourceFile } from '../libs/VitecNextProductSourceFileInfoHelper'
import Box from './Box'
import { VitecNextOrderProductStatusJaguarWarning } from './VitecNextOrderProductStatusJaguarWarning'
import { VitecNextProductSourceFileInfoDeprecated } from './VitecNextProductSourceFileInfoDeprecated'
import { VitecNextProductSourceFileInfoView } from './VitecNextProductSourceFileInfoView'
import { DrawerContext } from './useDrawerContext'

export type Props = VitecNextOrderProductProps & {
  drawerContext?: DrawerContext
}

export function VitecNextOrderProductStatus(props: Props) {
  const { vitecNextOrderId, vitecNextProduct, drawerContext } = props
  const { data: vitecNextOrder, isLoading } = useVitecNOOrder(vitecNextOrderId)
  const { data: leopardOrder } = useLeopardOrder(getLeopardOrderIdForOrder({ vitecNextProduct, vitecNextOrder }))

  const { data: results } = useJaguarResultExperimental(leopardOrder)
  const warnings = results?.warnings || []

  const sourceFile = useSourceFile(leopardOrder)

  const [fileName] = sourceFile || []

  if (warnings.length > 0)
    return (
      <Box fullWidth>
        <VitecNextOrderProductStatusJaguarWarning results={results} />
      </Box>
    )

  return (
    <Box fullWidth align="center">
      {fileName && (
        <Box onClick={() => drawerContext?.open()}>
          <VitecNextProductSourceFileInfoView fileName={fileName} />
        </Box>
      )}
    </Box>
  )
}
