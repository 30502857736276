import { minSpacing, smallSpacing } from '../enums/Spacings'
import Images from '../libs/Images'
import { Texts } from '../libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'

export function VitecNextProductSourceFileInfoView({ fileName }: { fileName?: string }) {
  return (
    <Box direction="row" fullWidth link>
      <Box>
        <FigmaImageContainer imageKey={Images.pngDocIcon} />
      </Box>
      <Box left spacing={smallSpacing}>
        <FigmaText textKey={Texts.standardDocumentSourceLabel} />
      </Box>
      <Box left spacing={minSpacing}>
        <FigmaText textKey={Texts.standardDocumentSourceFileName} text={fileName} />
      </Box>
    </Box>
  )
}
