import { ConstructionMethodResultsResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { TG_SEVERITIES } from '../../../../pure/libs/Consts'
import { ConstructionMethodItem } from '../components/VitecNextOrderProductConstrucationInfoItem'

export function getConstructionMethodItems(results?: ConstructionMethodResultsResponse) {
  const { building_components, building_condition, electrical_system } = results?.structured_outputs || {}

  const items: ConstructionMethodItem[] = []

  if (building_components?.report_date) {
    items.push({
      id: 'report_date',
      field: building_components?.report_date,
      text: `Informasjon er hentet fra vedlagte tilstandsrapport, datert ${building_components?.report_date?.description}`
    })

    items.push({ text: ``, id: 'new-line' })
  }

  Object.entries({
    building: 'Bygning',
    roof: 'Tak',
    chimney_fireplace: 'Pipe/Ildsted',
    windows: 'Vinduer',
    doors: 'Dører',
    stairs_access: 'Trapper/adkomst',
    balcony_terrace: 'Balkong/terrasse',
    plumbing: 'VVS-installasjoner',
    ventilation: 'Ventilasjon',
    technical_details: 'Tekniske detaljer'
  }).forEach(([id, label]) => {
    const value = building_components?.[id]?.description
    if (!value) return

    items.push({ id, field: building_components[id], text: `${label}: ${value}` })
    items.push({ text: ``, id: 'new-line' })
  })

  if (electrical_system) {
    items.push({
      id: 'electrical_system',
      text: `Elektrisk anlegg: ${electrical_system.description.description}\n\n`,
      field: electrical_system.description
    })

    electrical_system.questions_and_answers
      .map((qa) =>
        items.push({
          text: `${qa.question}\n\n- ${qa.answer}`,
          field: { ...qa, description: `${qa.question}: ${qa.answer}` },
          id: `electrical_system_${qa.question}`
        })
      )
      .join('\n\n')

    items.push({ text: ``, id: 'new-line' })
  }
  if (building_components?.additional_buildings) {
    Object.entries(building_components.additional_buildings).forEach(([name, building]) => {
      items.push({
        id: `additional_buildings_${name}`,
        text: `${name.charAt(0).toUpperCase() + name.slice(1)}: ${building.description}`,
        field: building
      })
      items.push({ text: ``, id: 'new-line' })
    })
  }
  if (building_condition) {
    items.push({ text: 'Tilstandsgrad:', id: 'Tilstandsgrad' })
    TG_SEVERITIES.forEach((severity) => {
      const buildingConditionItems = building_condition.filter((item) => item.severity === severity)
      if (!buildingConditionItems.length) return

      items.push({ text: severity, id: 'new-line' })

      buildingConditionItems.forEach((issue) =>
        items.push({
          text: `- ${issue.issue_name} | ${issue.description}${
            issue.cost_estimate ? ` | Kostnadsestimat: ${issue.cost_estimate}` : ''
          }\n`,
          field: issue,
          id: `building_condition_${issue.issue_name}`
        })
      )

      items.push({ text: '', id: 'new-line' })
    })

    items.push({
      text: '\nOpplistingen over er ikke uttømmende og du oppfordres til å lese og sette deg grundig inn i alt salgsmateriell.',
      id: 'building_condition_suffix'
    })
  }
  return items
}
