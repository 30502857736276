import Colors from '../assets/colors.json'

export const PrimaryColor = 'rgba(0, 11, 97, 1)'
export const PrimaryColor10 = 'rgba(0, 11, 97, 0.3)'

export const SecondaryColor = '#1D5FEA'

export const SecondaryColor100 = 'rgba(29, 95, 234, 1)'
export const SecondaryColor50 = 'rgba(29, 95, 234, 0.5)'
export const SecondaryColor30 = 'rgba(29, 95, 234, 0.3)'

export const Yellow = '#F1DE7C'
export const LightYellow = '#FFFADE'

export const LightGrey = '#E3E3E3'

export const SuccessColor = '#72CE6A'

export const RedRed = '#FF6262' //
export const Purple = '#CB2E72'

export const EmptyState = 'rgba(241, 222, 124, 0.3)'
export const Yellow50 = 'rgba(241, 222, 124, 0.5)'
export const Yellow25 = 'rgba(241, 222, 124, 0.25)'

export const EmptyStateBorder = '#D1BC52'

export default Colors
