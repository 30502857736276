import { BasicAssociationItems, JaguarBoundingBox } from '../leopard/JaguarExperimentalTypesDerived'
import { VitecNextEstate } from '../types/VitecNextEstate'
import { VitecNextImportBooleanType } from '../types/VitecNextImportBooleanType'
import { VitecNextImportDoubleType } from '../types/VitecNextImportNumberType'
import { VitecNextImportTextType } from '../types/VitecNextImportTextType'
import { VitecNextImportType } from '../types/VitecNextImportType'

export type BasicAssociationResponseItemsKeysNotInJaguar = 'has_board_approval' | 'has_preemptive'
export const BasicAssociationResponseItemsKeysNotInJaguarArray: BasicAssociationResponseItemsKeysNotInJaguar[] = [
  'has_board_approval',
  'has_preemptive'
] as const

export type BasicAssociationResponseItemsKey =
  | keyof BasicAssociationItems
  | BasicAssociationResponseItemsKeysNotInJaguar

export type HighlightedField = {
  id: string
  bounding_boxes: JaguarBoundingBox[]
}

export type BasicAssociationField = HighlightedField & {
  value: string
  key: BasicAssociationResponseItemsKey
  checked?: boolean
  fulfilledAt?: string | null
  isFulfilling?: boolean
  fileName?: string
}

export type BasicAssociationFields = Record<BasicAssociationResponseItemsKey, BasicAssociationField>

export function replaceNewlineWithSpaces(str: string): string {
  return str.toString().replace(/\n\s+/g, '\n')
}

export type VitecNextOrderProductBasicAssociationItemPure = {
  vitecNextImportType?: VitecNextImportType
  vitecNextImportTextType?: VitecNextImportTextType
  vitecNextImportDoubleType?: VitecNextImportDoubleType
  vitecNextImportBooleanType?: VitecNextImportBooleanType
  format?: (value: string) => string
  getValueFromEstate?: (e?: VitecNextEstate) => string | number | boolean | null | undefined
  enabled?: boolean
}

export const VitecNextOrderProductBasicAssociationItemsPure: {
  [property in BasicAssociationResponseItemsKey]: VitecNextOrderProductBasicAssociationItemPure
} = {
  felleskostnader_pr_mnd: {
    vitecNextImportType: VitecNextImportType.RentPrMonth,
    vitecNextImportDoubleType: VitecNextImportDoubleType.RentPrMonth,
    getValueFromEstate: (e) => e?.estatePrice?.rent?.rentPrMonth
  },
  borettslaget: {},
  borettslagets_org_nr: {},
  forretningsfører: {},
  styregodkjennelse: {
    vitecNextImportType: VitecNextImportType.BoardApproval,
    vitecNextImportTextType: VitecNextImportTextType.BoardApproval
  },
  forkjøpsrett: {
    vitecNextImportType: VitecNextImportType.Preemptive,
    vitecNextImportTextType: VitecNextImportTextType.Preemptive
  },
  forkjøpsrettsgebyr: {
    vitecNextImportType: VitecNextImportType.PreemptiveFee,
    vitecNextImportDoubleType: VitecNextImportDoubleType.PreemptiveFee,
    getValueFromEstate: (estate) => estate?.partOwnership?.preEmptiveFee
  },
  husdyrhold: {
    vitecNextImportType: VitecNextImportType.Animals,
    vitecNextImportTextType: VitecNextImportTextType.Animals
  },
  andel_fellesgjeld: {
    vitecNextImportType: VitecNextImportType.CollectiveDebt,
    vitecNextImportDoubleType: VitecNextImportDoubleType.CollectiveDebt,
    getValueFromEstate: (e) => e?.estatePrice?.collectiveDebt
  },
  andel_formue: {
    vitecNextImportType: VitecNextImportType.ShareJointCapital,
    vitecNextImportDoubleType: VitecNextImportDoubleType.ShareJointCapital,
    getValueFromEstate: (e) => e?.partOwnership?.shareJointCapital
  },
  sikringsordning_fellesgjeld: {
    vitecNextImportType: VitecNextImportType.GuaranteeFund,
    vitecNextImportTextType: VitecNextImportTextType.GuaranteeFund,
    format: replaceNewlineWithSpaces
  },
  borettslagets_forsikringsselskap: {
    vitecNextImportType: VitecNextImportType.InsuranceCompany,
    vitecNextImportTextType: VitecNextImportTextType.InsuranceCompany
  },
  polisenummer_felles_forsikring: {
    vitecNextImportType: VitecNextImportType.InsurancePolicy,
    vitecNextImportTextType: VitecNextImportTextType.InsurancePolicy
  },
  eierskiftegebyr: {
    vitecNextImportType: VitecNextImportType.ChangeOfOwnershipFee,
    vitecNextImportDoubleType: VitecNextImportDoubleType.ChangeOfOwnershipFee,
    getValueFromEstate: (estate) => estate?.partOwnership?.changeOfOwnershipFee
  },
  kommunale_avgifter_year: {
    getValueFromEstate: (estate) => estate?.estatePrice?.communityTax,
    enabled: false
  },
  eiendomsskatt: {}, // used to calculate kommunale_avgifter
  lånebetingelser_fellesgjeld: {
    vitecNextImportType: VitecNextImportType.LoanTermsCollectiveDebt,
    vitecNextImportTextType: VitecNextImportTextType.LoanTermsCollectiveDebt,
    format: replaceNewlineWithSpaces
  },
  has_board_approval: {
    vitecNextImportType: VitecNextImportType.HasBoardApproval,
    vitecNextImportBooleanType: VitecNextImportBooleanType.HasBoardApproval
  },
  has_preemptive: {
    vitecNextImportType: VitecNextImportType.HasPreemptiveRight,
    vitecNextImportBooleanType: VitecNextImportBooleanType.HasPreemptiveRight
  }
}
