import { DescriptionsResultsResponse } from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { Product } from '../../../../pure/leopard/Product'
import { DescriptionsFields } from '../../../../pure/libs/VitecNextOrderProductDescriptionsItemsPure'
import { upsertLeopardOrder } from '../libs/DBApiHandler'
import { useVitecNOOrder } from './QueryHooks'
import { useJaguarResultForVitecNextOrderDeprecated } from './useJaguarResults'

export const useDescriptionsFieldsState = (vitecNextOrderId: string) => {
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)

  const { data: results } = useJaguarResultForVitecNextOrderDeprecated<DescriptionsResultsResponse>({
    vitecNextOrder,
    vitecNextProduct: Product.DESCRIPTIONS
  })

  const fields: DescriptionsFields = {
    short_description: {
      value: results?.text_outputs?.short_description || '',
      key: 'short_description'
    },
    long_description: {
      value: results?.text_outputs?.long_description || '',
      key: 'long_description'
    },
    innhold: {
      value: results?.text_outputs?.innhold || '',
      key: 'innhold'
    }
  }

  const setFields = (fields: DescriptionsFields) => ({})

  return { fields, setFields }
}
