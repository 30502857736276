import { Product } from '../leopard/Product'
import { VitecNextOrderProductProps } from '../types/VitecNextOrderProductProps'
import { addQueryParamsToUrl } from './QueryParamHelper'

enum RoutePath {
  ROOT = '/',
  SANDBOX = '/sandbox',
  DASHBOARD = '/dashboard',
  PRICING = '/pricing',
  PRICING_WEB_IFRAME = '/pricing-web-iframe',
  SIGN_UP = '/signup',
  TERMS_AND_CPNDITIONS = '/terms-and-conditions',
  PRIVACY_POLICY = '/privacy-policy',
  SETTINGS = '/settings',
  EXTERNAL_SESSION = '/external-session',
  VITEC_NEXT_ORDER_LANDING = '/vitec-next-order-landing',
  VITEC_NEXT_ORDER = '/vitec-next-order',
  ESTATE = '/estate',
  VITEC_NEXT_ORDER_PRODUCT = '/vitec-next-order/:vitecNextOrderId/:vitecNextProduct',
  VITEC_NEXT_ORDER_PRODUCT_FEEDBACK = '/vitec-next-order/:vitecNextOrderId/:vitecNextProduct/feedback',
  VITEC_NEXT_ORDER_PRODUCT_DIFF = '/vitec-next-order/:vitecNextOrderId/:vitecNextProduct/diff',
  VITEC_NEXT_FAQ = '/vitec-next-faq',
  VISMA_ORDER_LANDING = '/visma-order-landing',

  // App legacy
  RESEND_SECURE_LINK = '/resend-secure-link',
  CREATE_AD = '/create-ad',
  PRODUCTS = '/products'
}

export const formatRoutePathVitecNextOrderProduct = (
  p: VitecNextOrderProductProps,
  routePath: RoutePath = RoutePath.VITEC_NEXT_ORDER_PRODUCT
) => formatRoutePath(routePath, p)

export function formatRoutePathVitexNextOrder(vitecNextOrderId: string, routePath = RoutePath.VITEC_NEXT_ORDER) {
  return addQueryParamsToUrl(routePath, { orderId: vitecNextOrderId })
}

export function formatRoutePathForExternalEstateId(externalEstateId: string) {
  return `${RoutePath.ESTATE}/${externalEstateId}`
}

export function formatRoutePathForProduct({
  externalEstateId,
  product
}: {
  externalEstateId: string
  product: Product
}) {
  return `${RoutePath.ESTATE}/${externalEstateId}/${product}`
}

const formatRoutePath = (r: RoutePath, o: object) =>
  Object.entries(o).reduce((acc, [k, v]) => acc.replace(`:${k}`, v), r as string)

export default RoutePath
