import {
  IsVitecNextProductEnabledResponse,
  IsVitecNextProductEnabledResponseCode
} from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import { Product } from '../../../../pure/leopard/Product'
import { toMB } from '../../../../pure/libs/Common'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { formatText, getFigmaText } from './TextRepository'
import { Texts } from './Texts'

export const IsVitecNextProductEnabledInfo: {
  [property in IsVitecNextProductEnabledResponseCode]: {
    getText: (args: {
      vitecNextOrder?: VitecNextOrder
      isVitecNextProductEnabled?: IsVitecNextProductEnabledResponse
      vitecNextProduct?: Product
    }) => string
  }
} = {
  DOCUMENT_NOT_FOUND: {
    getText: ({ vitecNextProduct }) => {
      if (vitecNextProduct === Product.IMAGE_CAPTIONS) return getFigmaText(Texts.ImageTextsNotificationItNoImgages)

      if (vitecNextProduct === Product.CONSTRUCTION_INFO)
        return getFigmaText(Texts.ConstructionNotificationCiMissingDocument)

      if (vitecNextProduct === Product.CONSTRUCTION_INFO_EXPERIMENTAL)
        return getFigmaText(Texts.ConstructionNotificationCiMissingDocument)

      if (vitecNextProduct === Product.DESCRIPTIONS)
        return getFigmaText(Texts.ConstructionNotificationCiMissingDocument)

      return getFigmaText(Texts.standardNotificationMissingDocument)
    }
  },
  DOCUMENT_TOO_BIG: {
    getText: ({ isVitecNextProductEnabled }) =>
      formatText(getFigmaText(Texts.standardTooBigDocument), [
        toMB(isVitecNextProductEnabled?.extra?.bytes || 0).toFixed(0)
      ])
  },
  ADDRESS_NOT_FOUND: {
    getText: ({ vitecNextOrder }) =>
      formatText(getFigmaText(Texts.AreaDescriptionMissingArea), [vitecNextOrder?.address || ''])
  }
}
