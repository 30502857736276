import styled from '@emotion/styled'
import { useState } from 'react'
import { FeatureFlag } from '../../../../pure/enums/FeatureFlags'
import {
  BuildingComponent,
  ConstructionMethodResultsResponse
} from '../../../../pure/leopard/JaguarExperimentalTypesDerived'
import { Product } from '../../../../pure/leopard/Product'
import Colors from '../../../../pure/libs/Colors'
import { getLeopardOrderId } from '../../../../pure/libs/getLeopardOrderId'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import { getGsUrlForLeopardOrder } from '../hooks/useDownloadUrl'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import { useJaguarResultExperimental } from '../hooks/useJaguarResults'
import { useLeopardOrder } from '../hooks/useLeopardOrder'
import { VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { JsonView } from './JsonView'
import { LoadingPage } from './LoadingPage'
import { PdfViewerProps } from './PdfViewer'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import { VitecNextOrderProductButtonsDeprecated as VitecNextOrderProductButtons } from './VitecNextOrderProductButtonsDeprecated'
import {
  ConstructionMethodItem,
  VitecNextOrderProductConstrucationInfoItem
} from './VitecNextOrderProductConstrucationInfoItem'
import { VitecNextOrderProductFeedbackButton } from './VitecNextOrderProductFeedbackButton'
import { VitecNextOrderProductHeader } from './VitecNextOrderProductHeader'
import { VitecNextOrderProductStatus } from './VitecNextOrderProductStatus'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { WithPdfDrawer } from './WithPdfDrawer'
import { useClickUpDown } from './useClickUpDown'
import { useDrawerContext } from './useDrawerContext'
import { getConstructionMethodItems } from '../libs/getConstructionMethodItems'
import { groupBy } from '../../../../pure/libs/Common'
import invariant from 'invariant'

export const VitecNextOrderProductContructionInfoExperimental = (props: VitecNextOrderProductProps) => {
  const enableDebug = useFeatureFlag(FeatureFlag.enableDebug)
  const { vitecNextOrderId } = props
  const vitecNextProduct = Product.CONSTRUCTION_INFO
  const item = VitecNextProductInfoItems[vitecNextProduct]
  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)
  const drawerContext = useDrawerContext('construction_info')
  const [selectedId, setSelectedId] = useState<string>('')
  const [selectedPage, setSelectedpage] = useState<string>('')

  const { data: leopardOrder } = useLeopardOrder(
    getLeopardOrderId({ externalEstateId: vitecNextOrder?.estateId, product: vitecNextProduct })
  )

  const { data: jaguarResult } = useJaguarResultExperimental<ConstructionMethodResultsResponse>(leopardOrder)

  const items: ConstructionMethodItem[] = getConstructionMethodItems(jaguarResult)

  const onClickHighlight = (item?: ConstructionMethodItem) => {
    if (!item) return
    console.log('onClickHighlight', item)
    setSelectedId(item.id)
    setSelectedpage(item.pageNumber?.toString() || '')
    drawerContext.highlight({ id: item.id, bounding_boxes: item.field?.bounding_boxes || [] })
  }

  const upDownItems = items
    .filter((i) => !!i.field)
    .map((i) => {
      invariant(i.field, '!i.field')
      const boundingBoxes = i.field.bounding_boxes || []
      const _boundingBoxes = groupBy(boundingBoxes, 'page')
      return Object.entries(_boundingBoxes)
        .sort(([a], [b]) => parseInt(a) - parseInt(b))
        .map(([page, boundingBoxes]) => ({
          ...i,
          pageNumber: page.toString(),
          field: { ...i.field, bounding_boxes: boundingBoxes } as BuildingComponent
        }))
    })
    .flat()

  useClickUpDown({
    items: upDownItems,
    selectedId: selectedId,
    selectedPage,
    onClickDown: (items, currentIndex) => onClickHighlight(items.find((_, index) => index === currentIndex + 1)),
    onClickUp: (items, currentIndex) => onClickHighlight(items.find((_, index) => index === currentIndex - 1))
  })

  const gsUrl = getGsUrlForLeopardOrder(leopardOrder)
  const pdfViewerPropSets: Partial<PdfViewerProps>[] = [
    {
      gsUrl,
      boundingBoxes: upDownItems
        .map((i) => {
          if (!i.field?.bounding_boxes) return []
          return i.field.bounding_boxes
        })
        .flat()
    }
  ]

  const status = jaguarResult?.status

  if (!vitecNextOrder) return <Box />
  if (!leopardOrder) return <Box />

  if (!jaguarResult?.structured_outputs) return <Box />

  return (
    <WithPdfDrawer pdfViewerPropSets={pdfViewerPropSets} drawerContext={drawerContext}>
      <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
        <Box>
          <VitecNextOrderProductBackButton vitecNextOrderId={vitecNextOrderId} />
        </Box>
        <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
          <VitecNextOrderProductHeader
            vitecNextOrder={vitecNextOrder}
            headerTextKey={item.textKey}
            vitecNextProduct={Product.CONSTRUCTION_INFO_EXPERIMENTAL}
          />
          <Box top>
            <FigmaImageContainer imageKey={Images.gazelleInCircle} />
          </Box>
          <Box fullWidth top spacing={smallSpacing}>
            <VitecNextOrderProductButtons
              vitecNextOrderId={vitecNextOrderId}
              vitecNextProduct={vitecNextProduct}
              fulfillMentSuccessText={getFigmaText(Texts.standardNotificationUploadComplete)}
              enableFulfillment={true}
            />
          </Box>

          <Box fullWidth top>
            <VitecNextOrderProductStatus {...props} drawerContext={drawerContext} />
          </Box>

          {!status && <LoadingPage />}

          <Box fullWidth>
            <Box fullWidth top>
              <Container fullWidth fullPadding position="relative">
                <Box direction="row" align="center">
                  <FigmaText textKey={Texts.ConstructionCiTextHeader} />
                </Box>
                <Box top>
                  <VitecNextOrderProductFeedbackButton {...props} />
                </Box>
                <Box top fullWidth>
                  {items.map((item, index) => (
                    <VitecNextOrderProductConstrucationInfoItem
                      key={index}
                      text={item.text}
                      isSelected={item.id === selectedId}
                      onClickHighlight={() => onClickHighlight(item)}
                      item={item}
                    />
                  ))}
                </Box>

                {enableDebug && <JsonView data={jaguarResult || {}} />}
              </Container>
            </Box>
          </Box>
        </Box>
        <Box visibility="hidden">
          <VitecNextOrderProductBackButton vitecNextOrderId={vitecNextOrderId} />
        </Box>
      </Box>
    </WithPdfDrawer>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`
