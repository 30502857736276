import { useEffect } from 'react'
import { AreGazelleProductsEnabledResponse } from '../../../../pure/leopard/AreGazelleProductsEnabledResponse'
import { IsVitecNextProductEnabledResponse } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import { Product } from '../../../../pure/leopard/Product'
import eventEmitter, { Events } from '../../../../pure/libs/EventEmitter'
import { getFromMemoryCache, withMemoryCache } from '../../../../pure/libs/withMemoryCache'
import { areGazelleProductsEnabled } from '../libs/CloudFunctionsApiHandler'
import { captureException } from '../libs/ErrorHelper'
import useAppState, { State } from './useAppState'
import { useState } from './useState'

type IsProductsEnabledResponse = {
  [key in Product]: IsVitecNextProductEnabledResponse | undefined
}

export function useAreGazelleProductsEnabled({
  vitecNextOrderId,
  externalEstateId,
  enable = true
}: {
  vitecNextOrderId?: string
  externalEstateId?: string
  enable?: boolean
}) {
  const { state } = useAppState()

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<IsProductsEnabledResponse>(undefined)

  useEffect(() => {
    if (!vitecNextOrderId) return
    if (!externalEstateId) return
    if (!enable) return

    createAreGazelleProductsEnabledFunction({
      externalEstateId,
      vitecNextOrderId,
      state
    })()
      .then((result) => {
        setData(result)
        eventEmitter.emit(Events.ON_ARE_GAZELLE_PRUDCTS_ENABLED_UPDATED)
      })
      .catch((error) => captureException(error))
      .finally(() => setIsLoading(false))
  }, [vitecNextOrderId, enable, externalEstateId])

  return { data, isLoading }
}

export function useAreProductsEnabledCache({
  vitecNextOrderId,
  externalEstateId
}: {
  vitecNextOrderId: string
  externalEstateId: string
}) {
  const { state } = useAppState()
  const [data, setData] = useState<AreGazelleProductsEnabledResponse | undefined>(undefined)
  useEffect(() => {
    if (!vitecNextOrderId) return
    if (!externalEstateId) return

    setData(getFromMemoryCache(getAreGazelleProductsEnabledCacheKey(vitecNextOrderId)))

    eventEmitter.on(Events.ON_ARE_GAZELLE_PRUDCTS_ENABLED_UPDATED, () =>
      createAreGazelleProductsEnabledFunction({ vitecNextOrderId, externalEstateId, state })().then((result) =>
        setData(result)
      )
    )

    return () => {
      eventEmitter.removeAllListeners(Events.ON_ARE_GAZELLE_PRUDCTS_ENABLED_UPDATED)
    }
  }, [vitecNextOrderId, externalEstateId])

  return { data, isLoading: false }
}

export function useIsProductEnabled({
  vitecNextOrderId,
  externalEstateId,
  vitecNextProduct
}: {
  vitecNextOrderId: string
  externalEstateId?: string
  vitecNextProduct: Product
}) {
  const { state } = useAppState()
  const areProductsEnabled: AreGazelleProductsEnabledResponse | undefined = getFromMemoryCache(
    getAreGazelleProductsEnabledCacheKey(vitecNextOrderId)
  )
  const [data, setData] = useState<IsVitecNextProductEnabledResponse | undefined>(
    areProductsEnabled?.[vitecNextProduct]
  )

  useEffect(() => {
    if (!externalEstateId) return
    const listener = () => {
      createAreGazelleProductsEnabledFunction({ vitecNextOrderId, externalEstateId, state })().then((result) =>
        setData(result[vitecNextProduct])
      )
    }

    eventEmitter.on(Events.ON_ARE_GAZELLE_PRUDCTS_ENABLED_UPDATED, listener)

    return () => {
      eventEmitter.removeListener(Events.ON_ARE_GAZELLE_PRUDCTS_ENABLED_UPDATED, listener)
    }
  }, [vitecNextOrderId, vitecNextProduct, externalEstateId])

  return { data, isLoading: false }
}

function createAreGazelleProductsEnabledFunction({
  vitecNextOrderId,
  externalEstateId,
  state
}: {
  vitecNextOrderId: string
  externalEstateId: string
  state: State
}) {
  return withMemoryCache(
    () => areGazelleProductsEnabled({ externalEstateId, legacyVitecNextOrderId: vitecNextOrderId }, state),
    getAreGazelleProductsEnabledCacheKey(vitecNextOrderId)
  )
}

function getAreGazelleProductsEnabledCacheKey(vitecNextOrderId: string): string {
  return `areGazelleProductsEnabled_${vitecNextOrderId}`
}
