import { Product } from '../../../../pure/leopard/Product'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { HardCodedTexts } from '../libs/Texts'
import Box from './Box'
import DashboardListItem from './DashboardListItem'
import { VitecNextOrderItemLongWaitFigmaText } from './VitecNextOrderItemLongWaitFigmaText'

export function DashboardList({ vitecNextOrder, now }: { vitecNextOrder: VitecNextOrder; now: string }) {
  return (
    <Box fullWidth>
      {[
        Product.IMAGE_CAPTIONS,
        Product.AREA_DESCRIPTION,
        Product.CONSTRUCTION_INFO,
        Product.DESCRIPTIONS,
        Product.SELF_DECLERATION,
        Product.BASIC_ASSOCIATION
      ]
        .filter((v) => !!v)
        .map((vitecNextProduct, i) => (
          <Box key={i} top spacing={tinySpacing} fullWidth>
            <DashboardListItem
              vitecNextOrderId={vitecNextOrder.orderId}
              vitecNextProduct={vitecNextProduct as Product}
              externalEstateId={vitecNextOrder.estateId}
              now={now}
            />
          </Box>
        ))}
      <Box align="center" fullWidth top>
        <VitecNextOrderItemLongWaitFigmaText
          vitecNextOrder={vitecNextOrder}
          figmaTextProps={{ styleTextKey: HardCodedTexts.dashboardLongwaitText }}
        />
      </Box>
    </Box>
  )
}
