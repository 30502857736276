import { useParams } from 'react-router-dom'
import { Layout } from '../components/Layout'
import { VitecNextOrderProduct } from '../components/VitecNextOrderProduct'
import { VitecNextOrderRedirectPage } from './DashboardPage'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { useVitecNOOrder } from '../hooks/QueryHooks'

export const VitecNextOrderProductPage = () => {
  const { vitecNextOrderId, vitecNextProduct } = useParams<VitecNextOrderProductProps>()

  const { data: vitecNextOrder } = useVitecNOOrder(vitecNextOrderId)

  if (!vitecNextOrderId) return <Layout enableMenu />
  if (!vitecNextOrder) return <Layout enableMenu />
  if (!vitecNextProduct) return <VitecNextOrderRedirectPage vitecNextOrderId={vitecNextOrderId} />

  return (
    <VitecNextOrderProduct
      vitecNextOrderId={vitecNextOrderId}
      vitecNextProduct={vitecNextProduct}
      externalEstateId={vitecNextOrder.estateId}
    />
  )
}
