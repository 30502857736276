import { VitecNextOrderProductBasicAssociationItemsPure } from '../../../../pure/libs/VitecNextOrderProductBasicAssociationItemsPure'
import { BasicAssociationResponseItemsKey } from '../../../../pure/libs/VitecNextOrderProductBasicAssociationItemsPure'
import { TextKey } from '../../../../pure/types/Antiloop'
import { Texts } from './Texts'

export const VitecNextOrderProductBasicAssociationItems: {
  [property in BasicAssociationResponseItemsKey]: {
    textKey?: TextKey
  }
} = {
  felleskostnader_pr_mnd: {
    textKey: Texts.BasicAssociationBaRentPrMonth
  },
  andel_formue: {
    textKey: Texts.BasicAssociationBaAndelFormue
  },
  borettslaget: {
    textKey: Texts.BasicAssociationBaPartName
  },
  borettslagets_org_nr: {
    textKey: Texts.BasicAssociationBaPartOrgNumber
  },
  styregodkjennelse: {
    textKey: Texts.BasicAssociationBaBoardApproval
  },
  forkjøpsrett: {
    textKey: Texts.BasicAssociationBaPreemptive
  },
  forkjøpsrettsgebyr: {
    textKey: Texts.BasicAssociationBaPreemtiveFee
  },
  kommunale_avgifter_year: {},
  eiendomsskatt: {},
  husdyrhold: {
    textKey: Texts.BasicAssociationBaAnimal
  },
  andel_fellesgjeld: {
    textKey: Texts.BasicAssociationBaCollectiveDebt
  },
  lånebetingelser_fellesgjeld: {
    textKey: Texts.BasicAssociationBaLoanTermsCollectiveDebt
  },

  sikringsordning_fellesgjeld: {
    textKey: Texts.BasicAssociationBaGuaranteeFund
  },
  borettslagets_forsikringsselskap: {
    textKey: Texts.BasicAssociationBaInsuranceCompany
  },
  polisenummer_felles_forsikring: {
    textKey: Texts.BasicAssociationBaInsurancePolicy
  },
  forretningsfører: {
    textKey: Texts.BasicAssociationBaBusinessManager
  },
  eierskiftegebyr: {
    textKey: Texts.BasicAssociationBaChangeOfOwnershipFee
  },
  has_board_approval: {},
  has_preemptive: {}
}

export const BasicAssociationKeysThatCanBeCantBeUploaded: BasicAssociationResponseItemsKey[] = Object.entries(
  VitecNextOrderProductBasicAssociationItemsPure
)
  .filter(([_, v]) => !v.vitecNextImportType)
  .map(([k, _]) => k as BasicAssociationResponseItemsKey)

export const BasicAssociationKeysAll = Object.keys(VitecNextOrderProductBasicAssociationItems)

export const BasicAssociationKeysThatCanBeUploaded: BasicAssociationResponseItemsKey[] = BasicAssociationKeysAll.filter(
  (k) => !BasicAssociationKeysThatCanBeCantBeUploaded.includes(k as BasicAssociationResponseItemsKey)
) as BasicAssociationResponseItemsKey[]
